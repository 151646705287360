import Navbar from '../components/Navbar';
import useAxios from "../utils/useAxios";
import { useState, useEffect } from 'react';
import { useToast } from "@chakra-ui/react";

import {
    Container,
    Box,
    VStack,
    StackDivider,
    useColorModeValue,
    Heading,
    Skeleton,
    Link,
    Text,
    SimpleGrid,
    Button,
    Select,
} from "@chakra-ui/react";
import DeviceTile from '../components/DeviceTile';
import { ChevronRightIcon } from "@chakra-ui/icons";

interface IData {
    id?: number;
    device_address?: string;
    version?: string;
    battery?: number;
    signal_strength?: number;
    mod?: number;
    interrupt?: number;
    soil_moisture?: number;
    soil_temperature?: number;
    soil_conductivity?: number;
    soil_dielectric_constant?: number;
    timestamp: string;
    created_at?: string;
    device?: number;
    air_temperature?: number;
    humidity?: number;
    lux?: number;
    battery_voltage?: number;
}

interface IDevice {
    device_id: number;
    device_name: string;
    device_description: string;
    device_type: number;
    data: IData;
    latitude?: number;
    longitude?: number;
    altitude?: number;
    accuracy?: number;
    altitudeAccuracy?: number;
    field?: number | null;
}

interface IField{
    id: number;
    field_name: string;
}

interface IDevFields{
    device_name: string;
    id: number;
    field: number | null;
}

type IDevices = IDevice[];


const deviceTypeLookup = new Map<number, string>([
    [-1, 'Dragino Sensors'],
    [0, 'Soil Sensors'],
    [1, 'Soil Sensors'],
    [2, 'AutoPlants'],
    [3, 'Environment Sensors'],
    [4, 'Relays'],
    [5, 'Valves'],
    [6, 'Valves With Flow Sensor'],
    [7, 'Pumps'],
]);

const DeviceOverview = () => {
    const api = useAxios();
    const toast = useToast();

    const [isLoading, setIsLoading] = useState(true);
    const [devices, setDevices] = useState<IDevices>([]);
    const box_color = useColorModeValue('white', 'gray.900');
    const link_color = useColorModeValue('teal.600', 'teal.200');
    const [fields, setFields] = useState<IField[]>([]);
    const [devFields, setDevFields] = useState<IDevFields[]>([]);

    const [selectedField, setSelectedField] = useState<number>(0);

    const textColor = useColorModeValue('gray.800', 'gray.200');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get("frontend/devices/current-data/");
                console.log(response.data);
                setDevices(response.data);

                setIsLoading(false);
            } catch (error: any) {
                if (error.response.status === 401) {
                    // Currently, this is handled elsewhere
                    return;
                }
                setDevices([]);
                setIsLoading(false);
                // Toast
                toast({
                    title: "Error fetching data",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchFields = async () => {
            try {
                const response = await api.get("/agtech/fields/")
                setFields(response.data);

                    
            } catch (error: any) {
                if (error.response.status === 401) {
                    // Currently, this is handled elsewhere
                    return;
                }
                setFields([]);
                // Toast
                toast({
                    title: "Error fetching data",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        };
        fetchFields();
    }, []);

    useEffect(() => {
        const fetchDevFields = async () => {
            try{
                const response = await api.get("/frontend/user-devices/")
                setDevFields(response.data);
            } catch (error: any) {
                if (error.response.status === 401) {
                    // Currently, this is handled elsewhere
                    return;
                }
                setDevFields([]);
                // Toast
                toast({
                    title: "Error fetching data",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        }; 
        fetchDevFields();
    }, []);

    //United array of devices
    const mergedDevices: IDevice[] = devices.map((dev) => {
        const found = devFields.find((df) => df.id === dev.device_id);
        return {
            ...dev,
            field: found?.field ?? null,
        };
    });

    //Filter mergedDevices based on the selected field
    const filteredDevices = mergedDevices.filter((dev) => {
        if( selectedField === -1){
            return false;
        }
        if (selectedField === 0) {
            return true;
        }
        if (selectedField === 1) {
            return dev.field === null;
        }
        return dev.field === selectedField;
    });

    

  // Group devices by type
    const groupedDevices = filteredDevices.reduce((groups: Record<string, IDevice[]>, device) => {
        const deviceTypeName = deviceTypeLookup.get(device.device_type) || 'UNKNOWN';
        if (!groups[deviceTypeName]) {
            groups[deviceTypeName] = [];
        }
        groups[deviceTypeName].push(device);
        return groups;
    }, {});



    return (
        <Navbar>
            <Box mx={{ base: 2, sm: 4, md: 6 }} p={5} >
                <VStack
                    mt={2}
                    spacing={4}
                    align="stretch"
                >
                    <SimpleGrid minChildWidth={{ base: '40' }} spacing="5">
                        <Heading fontSize={{ base: '2xl', sm: '3xl' }}>
                            Device overview
                        </Heading>
                        <Link href="/add-device" color={link_color} fontSize={{ base: 'l', sm: 'xl' }} fontWeight={"400"}>
                            <Button size="lg" colorScheme="teal" variant="outline" rightIcon={<ChevronRightIcon />} bgColor={box_color}>
                                <Text>
                                    Add new device
                                </Text>
                            </Button>
                        </Link>
                    </SimpleGrid>

                    <Box>
                        <Text mb={2} fontWeight="semibold">
                            Filter by field:
                        </Text>
                        <Select
                            value={selectedField}
                            onChange={(e) => setSelectedField(Number(e.target.value))}
                            maxW="300px"
                        >
                            <option value={-1}>Select field...</option>
                            <option value={0}>All fields</option>
                            <option value={1}>Unattached (no field)</option>
                            {fields.map((field) => (
                                <option key={field.id} value={field.id}>
                                    {field.field_name}
                                </option>
                            ))}
                        </Select>
                    </Box>

                    {isLoading ? (
                        <Skeleton height="20px" />
                    ) : (
                        Object.entries(groupedDevices).map(([deviceTypeName, devices]) => (
                            <Box key={deviceTypeName} mt={4}>
                                <Heading size="md" mb={2}>
                                    {deviceTypeName}
                                </Heading>
                                <SimpleGrid minChildWidth="250px" spacing={4}>
                                    {devices.map((device) => (
                                        <Box
                                            borderRadius="lg"
                                            p={4}
                                            m={2}
                                            boxShadow="lg"
                                            bg={box_color}
                                            key={device.device_id}
                                            color={textColor}
                                        >
                                            <Link href={`/devices/${device.device_id}`}>
                                                <DeviceTile device={device} />
                                            </Link>
                                        </Box>
                                    ))}
                                </SimpleGrid>
                            </Box>
                        ))
                    )}
                </VStack>
            </Box>
        </Navbar>
    );
}

export type { IDevice, IDevices, IData };
export default DeviceOverview;