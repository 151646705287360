import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link as RouterLink } from 'react-router-dom';

import {
    Box,
    Heading,
    useToast,
    Button,
    FormControl,
    FormLabel,
    Input,
    useColorModeValue,
    Center,
    VStack,
    Text,
    Stack,
    SimpleGrid,
    Container,
    Flex,
    Link,
    Spacer,
    Skeleton
} from "@chakra-ui/react";

import { Trans } from "@lingui/react/macro";

import FieldMap from '../../components/map/FieldMap';
import NotesWidget from '../../components/FieldComponents.tsx/NotesWidget';
import MoistureWidget from '../../components/FieldComponents.tsx/MoistureWidget';
import FieldweatherForecasstWidget from '../../components/FieldComponents.tsx/FieldWheatherForecastWidget' //Open Meteo Widget

import {Field} from '../../types/fieldInterface';

import Navbar from '../../components/Navbar';
import useAxios from "../../utils/useAxios";

function formatSize(sizeInMetres: number) {
    const sizeInDecares = sizeInMetres / 1000; // 1 decare is 1000 square metres
    const sizeInHectares = sizeInMetres / 10000; // 1 hectare is 10000 square metres
    

    if (sizeInHectares >= 1) {
        return `${sizeInHectares.toFixed(1)} hectares`;
    } else if (sizeInDecares >= 1) {
        return `${sizeInDecares.toFixed(1)} decares`;
    } else {
        return `${sizeInMetres.toFixed(1)} m^2`;
    }
}

interface IAlarm {
    id: number;
    is_active: boolean;
    alarm_type: string;
    trigger_value: number;
    trigger_below_or_above: boolean;
    device_is_offline: boolean;
    device_name: string;
    device: number;
    field_id?: number;
    field_name?: string;
}

const FieldView = () => {
    const api = useAxios();
    const toast = useToast();
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const [isLoading, setIsLoading] = useState(true);
    const [alarms, setAlarms] = useState<IAlarm[]>([]);
    const [fieldAlarms, setFieldAlarms] = useState<IAlarm[]>([]);

    const [field, setField] = useState<Field | null>(null);

    useEffect(() => {
        if (!id) return;
        const fetchData = async () => {
            try {
                const response = await api.get(`agtech/fields/${id}/`);
                setField(response.data);
                setIsLoading(false);
            } catch (error: any) {
                if (error.response.status === 401) {
                    // Currently, this is handled elsewhere
                    return;
                }
                // Toast
                toast({
                    title: <Trans>Error fetching data</Trans>,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }
        };
        fetchData();
    }, [id]);

    useEffect(() => {
        if (!id) return;
        const fetchAlarms = async () => {
            try {
                const response = await api.get(`agtech/fields/${id}/alarms/`);
                setAlarms(response.data);
            } catch (error: any) {
                if (error.response.status === 401) {
                    // Currently, this is handled elsewhere
                    return;
                }
                // Toast
                toast({
                    title: <Trans>Error fetching data</Trans>,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }
        };
        fetchAlarms();
    }, [id]);

    useEffect(() => {
        if (!id) return;
        const fetchFieldAlarms = async () => {
            try {
                const response = await api.get('/frontend/field-alarms/');
                setFieldAlarms(response.data);
            } catch (error: any) {
                if (error.response.status === 401) {
                    // Currently, this is handled elsewhere
                    return;
                }
                // Toast
                toast({
                    title: <Trans>Error fetching data</Trans>,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }
        };
        fetchFieldAlarms();
    }, [id]);
    
    const filteredFieldAlarms = field ? fieldAlarms.filter(alarm => alarm.field_id === field.id) : [];

    return (
        <Navbar>
            <Skeleton isLoaded={!isLoading}>
            <Flex flexDirection={{base: "column", lg: "row"}} justify="space-between" verticalAlign={"start"}>
                <Container p={4} bg={useColorModeValue('white', 'gray.900')} boxShadow="lg" borderRadius="md" mt="4" mx={{base:2, md:4}}>
                    <Heading as="h1" size="xl" textAlign="center" mb={4}>
                        {field?.field_name}
                    </Heading>
                        <Box>
                            <VStack align="start">
                                {field?.field_center_lat && field?.field_center_lon && (
                                    <>
                                        <Text fontWeight={"bold"}>
                                            <Trans>Lat/long:</Trans>
                                        </Text>
                                        <Text fontWeight={"400"}>
                                            {field.field_center_lat.toFixed(5)}, {field.field_center_lon.toFixed(5)}
                                        </Text>
                                    </>
                                )}
                                <Text fontWeight={"bold"}><Trans>Size:</Trans></Text>
                                <Text fontWeight={"400"}>
                                { field?.field_size && formatSize(field.field_size)}
                                </Text>

                                <Text fontWeight={"bold"}><Trans>Alarms:</Trans></Text>
                                {alarms.length === 0 && filteredFieldAlarms.length <= 1 ? (
                                    <>
                                        <Skeleton isLoaded={!isLoading}>
                                            <Text><Trans>You have not configured any alarms for this field.</Trans> <Link textDecoration="underline" color="blue.500" as={RouterLink} to={'/alarms'}><Trans>Configure</Trans></Link></Text>
                                        </Skeleton>
                                    </>
                                ) : ( alarms.map((alarm) => (
                                    alarm.is_active ? (
                                        <>                                            
                                        <Stack direction="row" spacing={2}>
                                            <Text key={alarm.id}>
                                            <Trans>❗Alarm for</Trans> <Link textDecoration="underline" textColor="blue.500" as={RouterLink} to={`/devices/${alarm.device}`}> {alarm.device_name ? alarm.device_name : `Device ${alarm.device}`}</Link>
                                                : {alarm.alarm_type} {alarm.trigger_below_or_above ? "<" : ">"} {alarm.trigger_value}
                                            </Text>
                                            <Spacer />
                                            <Link textDecoration="underline" textColor="blue.500" as={RouterLink} to={`/alarms/edit/${alarm.id}`}><Trans>Alarm setup</Trans></Link>
                                            </Stack>                                  
                                        </>
                                    ) : (
                                        <>
                                            <Text key={alarm.id}>
                                            ✅ {alarm.device_name}: {alarm.alarm_type} {alarm.trigger_below_or_above ? "<" : ">"} {alarm.trigger_value}
                                            </Text>
                                        </>
                                    )
                                )))
                                }

                                { filteredFieldAlarms.map((alarm) => (
                                    alarm.is_active ? (
                                        <>                                            
                                        <Stack direction="row" spacing={2}>

                                            <Text key={alarm.id}>
                                            <Trans>❗Alarm for</Trans> <Link textDecoration="underline" textColor="blue.500" as={RouterLink} to={`/devices/${alarm.device}`}> {alarm.device_name ? alarm.device_name : `Device ${alarm.device}`}</Link>
                                                : {alarm.alarm_type} {alarm.trigger_below_or_above ? "<" : ">"} {alarm.trigger_value}
                                            </Text>
                                            <Spacer />
                                            <Link textDecoration="underline" textColor="blue.500" as={RouterLink} to={`/alarms/alarm-field-edit/${alarm.id}`}><Trans>Alarm setup</Trans></Link>
                                            </Stack>                                  
                                        </>
                                    ) : (
                                        <>
                                            <Text key={alarm.id}>
                                            <Trans>✅ Field Alarm:</Trans> {alarm.alarm_type}: {alarm.trigger_below_or_above ? "<" : ">"} {alarm.trigger_value}
                                            </Text>
                                        </>
                                    )
                                ))
                                }
                                
                            </VStack>
                        </Box>

                        <Box>

                        </Box>
                </Container>
                <Container p={{base:2, md:4}} bg={useColorModeValue('white', 'gray.900')} size={"5xl"} boxShadow="lg" borderRadius="md" mt="4" maxW={"5xl"}>
                    <FieldweatherForecasstWidget id={id} />
                </Container>
            </Flex>
            <Container p={{base:2, md:4}} bg={useColorModeValue('white', 'gray.900')} size={"5xl"} boxShadow="lg" borderRadius="md" mt="4" maxW={{base:"100%", "2xl":"80%"}}>
                <FieldMap field_id={Number(id)} />
            </Container>
            <Container p={{base:2, md:4}} bg={useColorModeValue('white', 'gray.900')} size={"5xl"} boxShadow="lg" borderRadius="md" mt="4" maxW={{base:"100%", "2xl":"80%"}}>
                <MoistureWidget field={field as Field} />
            </Container>

            <Container p={{base:2, md:4}} bg={useColorModeValue('white', 'gray.900')} size={"5xl"} boxShadow="lg" borderRadius="md" my="4" maxW={"5xl"}>
                <NotesWidget field={field as Field} />
            </Container>
            </Skeleton>
        </Navbar>
    );
}

export default FieldView;