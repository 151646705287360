import { useEffect, useState } from 'react';
import { Link as RouterLink  } from 'react-router-dom';
import { Box, Heading, Text, useToast, Container, Flex, Divider, Skeleton, Show, useColorModeValue,Table, Tbody, Tr, Td, Link } from "@chakra-ui/react";
import useAxios from "../../utils/useAxios";
import { Field } from '../../types/fieldInterface';
import { Trans } from "@lingui/react/macro";

interface WheatherForecast{
    
    daily: {
        time: string[];
        temperature_2m_max: number[];
        temperature_2m_min: number[];
        precipitation_sum: number[];
        wind_speed_10m_max: number[];
        weather_code: number[];
    }
    hourly: {
        time: string[];
        cloud_cover: number[];
        et0_fao_evapotranspiration: number[];
        soil_temperature_6cm: number[];
        soil_temperature_18cm: number[];
        soil_temperature_54cm: number[];
        soil_moisture_9_to_27cm: number[];
        soil_moisture_27_to_81cm: number[];
    }
}

const wmoCodeToEmojiMap: Record<number, string> = {
    0: '☀️',   // Clear sky
    1: '🌤️',   // Mainly clear
    2: '⛅',    // Partly cloudy
    3: '☁️',   // Overcast
    45: '🌫️',  // Fog
    48: '🌫️',  // Depositing rime fog
    51: '🌧️',  // Drizzle: Light intensity
    53: '🌧️',  // Drizzle: Moderate intensity
    55: '🌧️',  // Drizzle: Dense intensity
    56: '🌧️',  // Freezing Drizzle: Light intensity
    57: '🌧️',  // Freezing Drizzle: Dense intensity
    61: '🌧️',  // Rain: Slight intensity
    63: '🌧️',  // Rain: Moderate intensity
    65: '🌧️',  // Rain: Heavy intensity
    66: '🌧️',  // Freezing Rain: Light intensity
    67: '🌧️',  // Freezing Rain: Heavy intensity
    71: '❄️',   // Snow fall: Slight intensity
    73: '❄️',   // Snow fall: Moderate intensity
    75: '❄️',   // Snow fall: Heavy intensity
    77: '❄️',   // Snow grains
    80: '🌧️',  // Rain showers: Slight intensity
    81: '🌧️',  // Rain showers: Moderate intensity
    82: '🌧️',  // Rain showers: Violent intensity
    85: '❄️',   // Snow showers: Slight intensity
    86: '❄️',   // Snow showers: Heavy intensity
    95: '⛈️',   // Thunderstorm: Slight or moderate
    96: '⛈️',   // Thunderstorm with slight hail
    99: '⛈️'    // Thunderstorm with heavy hail
};

const getWeatherIcon = (code: number) => {
    return wmoCodeToEmojiMap[code] || '❓';
};

const WheatherForecastTableData = ({ id, forecast_days = 8 }: { id: string|undefined, forecast_days?: number }) => {
    const [weatherData, setWeatherData] = useState<WheatherForecast | null>(null);
    const api = useAxios();
    const toast = useToast();
    const backgroundColor = useColorModeValue('gray.50', 'gray.800');
    const bgColor = useColorModeValue('white', 'gray.900');

    useEffect(() => {
        const fetchData = async () => {
            if (!id) return;
            try {
                const response = await api.get(`/agtech/weather-forecast/daily/${id}/`);
                const hourlyResponse = await api.get(`/agtech/weather-forecast/hourly/${id}/`);
                setWeatherData({ ...response.data, hourly: hourlyResponse.data.hourly });
            } catch (error: any) {
                setWeatherData(null);
                console.log(error);
                toast({
                    title: "Error fetching weather data",
                    status: "error",
                    description: error.code,
                    duration: 9000,
                    isClosable: true,
                });
            }
        };
        fetchData();
    }, [id]);

    if (!weatherData) {
        return <Skeleton height="400px" />;
    }

    return (
        <Container py={3} boxShadow="none" maxH='100%' maxW='100%'>
            <Flex alignItems="center" justifyContent="space-between">
                <Heading size="lg" mb={4} textAlign="center">
                    <Trans>Weather Forecast</Trans>
                </Heading>
                { forecast_days < 9 && (
                    <Link as={RouterLink} to={`/field/${id}/additional-info`} fontSize="md" href="../../components/FieldComponents.tsx/AdditionalInformationWidget">
                        <Trans>Show More</Trans>
                    </Link>
                )}
            </Flex>
            <Flex alignItems="center" justifyContent="space-between" flexDirection={{base:"column", '2xl':"row"}}>
                { forecast_days < 9 && (
                <>
                    <Box flex={1} p={4} borderRadius="md" boxShadow="md" bg={backgroundColor} minH="400px" width={{base:"100%", '2xl':"25%"}}>
                        <Text fontSize="xl" fontWeight="bold">
                            {new Date(weatherData.daily.time[0]).toLocaleDateString('en-GB', {
                                    day: 'numeric',
                                    month: 'long',
                                    year: 'numeric'
                            })}
                        </Text>
                        <Flex alignItems="center" mt={4}>
                            <Text fontSize={40}> {getWeatherIcon(weatherData.daily.weather_code[0])} </Text>
                            <Box ml={4}>
                                <Text 
                                    fontSize="3xl" 
                                    fontWeight="bold" 
                                    color={((weatherData.daily.temperature_2m_max[0] + weatherData.daily.temperature_2m_min[0]) / 2) < 0 ? "blue.500" : "red.500"}
                                >
                                    {((weatherData.daily.temperature_2m_max[0] + weatherData.daily.temperature_2m_min[0]) / 2).toFixed(1)}°C
                                </Text>
                                <Text fontSize="sm">
                                    <Trans>Min:</Trans>{" "}
                                    <Text as="span" color={weatherData.daily.temperature_2m_min[0] < 0 ? "blue.500" : "red.500"}>
                                        {weatherData.daily.temperature_2m_min[0]}°C
                                    </Text>{" "}
                                    | <Trans>Max:</Trans>{" "}
                                    <Text as="span" color={weatherData.daily.temperature_2m_max[0] < 0 ? "blue.500" : "red.500"}>
                                        {weatherData.daily.temperature_2m_max[0]}°C
                                    </Text>
                                </Text>
                            </Box>
                        </Flex>
                        <Box mt={4}>
                            <Text fontSize="sm">
                                <Trans>Precipitation:</Trans> {weatherData.daily.precipitation_sum[0]} mm
                            </Text>
                            <Divider my={1} borderColor="gray.300" />
                            <Text fontSize="sm">
                                <Trans>Wind Speed:</Trans> {weatherData.daily.wind_speed_10m_max[0]} km/h
                            </Text>
                            <Divider my={1} borderColor="gray.300" />
                            <Text fontSize="sm">
                                <Trans>Cloud Cover:</Trans> {weatherData.hourly.cloud_cover[0]}%
                            </Text>
                            <Divider my={1} borderColor="gray.300" />
                            <Text fontSize="sm">
                                <Trans>Evapotranspiration:</Trans> {weatherData.hourly.et0_fao_evapotranspiration[0]} mm
                            </Text>
                        </Box>
                    </Box>
                    
                    <Show above='2xl'>
                        <Divider orientation="vertical" height="400px" mx={4} borderColor="gray.300" />
                    </Show>

                    <Show below='2xl'>
                        <Divider orientation="horizontal" width="100%" my={5} borderColor="gray.300" />
                    </Show>
                </>
                )}
                <Box flex={2} p={4} borderRadius="md" boxShadow="md" bg={backgroundColor} minH="400px" width={{base:"100%", '2xl':"70%"}}>
                    <Text fontSize="xl" fontWeight="bold" textAlign="center">
                        {forecast_days-1} <Trans>Day Forecast</Trans>
                    </Text>
                    <Table variant="simple" mt={4}>
                        <Tbody>
                            {weatherData.daily.time.slice(1, forecast_days).map((date, i) => (
                                <Tr key={i + 1}>
                                    <Td py={3} px={1} textAlign="center">{new Date(date).toLocaleDateString('en-GB', { weekday: 'short', day: 'numeric', month: 'short' })}</Td>
                                    <Td py={3} px={1} textAlign="center" fontSize={26}>{getWeatherIcon(weatherData.daily.weather_code[i + 1])}</Td>
                                    <Td py={3} px={1} textAlign="center">
                                        <Text as="span" color={weatherData.daily.temperature_2m_min[i+1] < 0 ? "blue.500" : "red.500"}>
                                            {weatherData.daily.temperature_2m_min[i+1]}°
                                        </Text>
                                        {" | "}
                                        <Text as="span" color={weatherData.daily.temperature_2m_max[i+1] < 0 ? "blue.500" : "red.500"}>
                                            {weatherData.daily.temperature_2m_max[i+1]}°C
                                        </Text>
                                    </Td>
                                    <Td py={3} px={1} textAlign="center">{weatherData.daily.precipitation_sum[i + 1]} mm</Td>
                                    <Td py={3} px={1} textAlign="center">{weatherData.daily.wind_speed_10m_max[i + 1]} km/h</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
            </Flex>
        </Container>
    );
};
 
export default WheatherForecastTableData;



