import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    HStack,
    InputRightElement,
    Stack,
    Button,
    Heading,
    Text,
    useColorModeValue,
    Link,
    useToast,
  } from '@chakra-ui/react';
  import { useState } from 'react';

  import { useNavigate } from 'react-router-dom';
  import { Trans } from "@lingui/react/macro";

// endpoint: /users/dj-rest-auth/password/reset/ with email

export default function RequestPasswordResetView() {
    const toast = useToast();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [response, setResponse] = useState('');

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const response = await fetch(process.env.REACT_APP_API_URL + 'api/v1/users/dj-rest-auth/password/reset/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
            }),
        });
        
        if (response.ok) {
            toast({
                title: <Trans>Password reset email sent if account exists</Trans>,
                description: <Trans>Please check your email for a link to reset your password.</Trans>,
                status: "success",
                duration: 9000,
                isClosable: true,
            });
            navigate('/login');
        }
        else {
            // get response data
            const data = await response.json();
            setResponse(data);
            toast({
                title: <Trans>Error</Trans>,
                description: JSON.stringify(data, null, 2),
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    }

    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.900')}>
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                <Stack align={'center'}>
                    <Heading fontSize={'4xl'}><Trans>Request password reset</Trans></Heading>
                    <Text fontSize={'lg'} color={'gray.600'}>
                        <Trans>Enter your email address and we will send you a link to reset your password.</Trans>
                    </Text>
                </Stack>
                <Box
                    rounded={'lg'}
                    bg={useColorModeValue('white', 'gray.700')}
                    boxShadow={'lg'}
                    p={8}>
                    <Stack spacing={4}>
                        <FormControl id="email" isRequired>
                            <FormLabel><Trans>Email address</Trans></FormLabel>
                            <Input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </FormControl>
                        <Stack spacing={10}>
                            <Button
                                colorScheme='green'
                                onClick={handleSubmit}>
                                <Trans>Request password reset</Trans>
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        </Flex>
    );
}