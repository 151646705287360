import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import {
    Box,
    Heading,
    useToast,
    Button,
    FormControl,
    FormLabel,
    Input,
    useColorModeValue,
    Center,
    VStack,
    Text,
    Stack,
    SimpleGrid,
    Container,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    Select,
} from "@chakra-ui/react";

import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

import {Field, CropType} from '../../types/fieldInterface';

import MapSelector2 from '../../components/MapSelector2';
import Navbar from '../../components/Navbar';
import useAxios from "../../utils/useAxios";
import SoilSettingsTab from '../../components/FieldComponents.tsx/SoilSettingsTab';
import MoistureLimitsTab from '../../components/FieldComponents.tsx/MoistureLimitsTab';
import { Trans } from "@lingui/react/macro";

const AddModifyField = () => {
    const api = useAxios();
    const toast = useToast();
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const [isLoading, setIsLoading] = useState(true);

    const [field, setField] = useState<Field>({});
    const [fieldName, setFieldName] = useState<string>('');
    const [fieldArea, setFieldArea] = useState<string>('');

    const  [cropTypes, setCropTypes] = useState<CropType[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(`agtech/fields/${id}/`);
                setField(response.data);
                console.log(response.data);
                setFieldName(response.data.field_name);
                setFieldArea(response.data.area);
            } catch (error: any) {
                // Toast
                toast({
                    title: <Trans>Error fetching data</Trans>,
                    status: "error",
                    description: error.response.data,
                    duration: 9000,
                    isClosable: true,
                });
            }
        };
        if (id) {
            fetchData();
        } else {
            setIsLoading(false);
        }
    }, [id]);

    useEffect(() => {
        if (id) {
            if (fieldArea) {
                setIsLoading(false);
            }
        }
    }, [fieldArea]);

    useEffect(() => {
        const fetchCropTypes = async () => {
            try {
                const response = await api.get('agtech/crop-types/');
                console.log(response.data);
                setCropTypes(response.data);
            } catch (error: any) {
                // Toast
                toast({
                    title: <Trans>Error fetching data</Trans>,
                    status: "error",
                    description: error.response.data,
                    duration: 9000,
                    isClosable: true,
                });
            }
        };
        fetchCropTypes();
    }, []);

    const handleSave = async () => {

        // Validate that fieldArea is set
        if (!fieldArea) {
            toast({
                title: <Trans>Field area not set</Trans>,
                description: <Trans>Please set the field area</Trans>,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return;
        }

        try {
            if (field.id) {
                await api.put(`agtech/fields/${id}/`, {
                    field_name: fieldName,
                    crop_type: field.crop_type,
                    area: fieldArea,
                    soil_texture_sand: field.soil_texture_sand,
                    soil_texture_silt: field.soil_texture_silt,
                    soil_texture_clay: field.soil_texture_clay,
                    soil_texture_organic_matter: field.soil_texture_organic_matter,

                    moisture_limit_critical: field.moisture_limit_critical,
                    moisture_limit_damage: field.moisture_limit_damage,
                    moisture_limit_deficit: field.moisture_limit_deficit,
                    moisture_limit_field_capacity: field.moisture_limit_field_capacity

                });
            } else {
                await api.post(`agtech/fields/`, {
                    field_name: fieldName,
                    area: fieldArea,
                    crop_type: field.crop_type,
                    soil_texture_sand: field.soil_texture_sand,
                    soil_texture_silt: field.soil_texture_silt,
                    soil_texture_clay: field.soil_texture_clay,
                    soil_texture_organic_matter: field.soil_texture_organic_matter,

                    moisture_limit_critical: field.moisture_limit_critical,
                    moisture_limit_damage: field.moisture_limit_damage,
                    moisture_limit_deficit: field.moisture_limit_deficit,
                    moisture_limit_field_capacity: field.moisture_limit_field_capacity

                });
            }
            navigate('/fields');
            
            // Toast
            toast({
                title: <Trans>Data saved</Trans>,
                description: <Trans>Data has been saved successfully</Trans>,
                status: "success",
                duration: 9000,
                isClosable: true,
            });


        } catch (error: any) {
            console.log(error)
            // Toast
            toast({
                title: <Trans>Error saving data</Trans>,
                description: error.response.data,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const [tabIndex, setTabIndex] = useState(0);

    const handleNext = () => {
        setTabIndex((prevIndex) => (prevIndex + 1) % 3);
    };

    const handlePrevious = () => {
        setTabIndex((prevIndex) => (prevIndex - 1 + 3) % 3);
    };

    return (
        <Navbar>
            <Container bg={useColorModeValue('white', 'gray.900')} p={4} borderRadius={8} boxShadow="md" mt={4} maxW="2xl">
            <Box>
                <Heading as="h1" size="2xl" textAlign="center" mb={4}>
                    {field.id ? <Trans>Modify Field</Trans> : <Trans>Add Field</Trans>}
                </Heading>

                    <VStack spacing={4} align="stretch">
                        <FormControl id="field-name" isRequired>
                            <FormLabel><Trans>Field Name</Trans></FormLabel>
                            <Input
                                type="text"
                                value={fieldName}
                                onChange={(e) => setFieldName(e.target.value)}
                                placeholder={"e.g. \"My Field\""}
                            />
                        </FormControl>
                        <FormControl id="crop-type" isRequired>
                            <FormLabel><Trans>Crop Type</Trans></FormLabel>
                            <Select
                                placeholder={"Select crop type"}
                                value={field?.crop_type}
                                onChange={(e) => {
                                    if (field) {
                                        setField({
                                            ...field,
                                            crop_type: Number(e.target.value),
                                        });
                                    }
                                }}
                            >
                                {cropTypes.map((cropType) => (
                                    <option key={cropType.id} value={cropType.id}>{cropType.name}</option>
                                ))}
                            </Select>
                        </FormControl>
                    <Tabs  index={tabIndex} onChange={(index) => setTabIndex(index)}>
                        <TabList>
                            <Tab><Trans>Field Area</Trans></Tab>
                            <Tab><Trans>Soil Settings</Trans></Tab>
                            <Tab><Trans>Moisture Limits</Trans></Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <FormLabel><Trans>Field Area</Trans></FormLabel>
                                <Text><Trans>Click on the map to draw the field area</Trans></Text>
                                { ! isLoading ?
                                    <MapSelector2 features={fieldArea} setFeatures={setFieldArea} />
                                    : <Center><Text><Trans>Loading...</Trans></Text></Center>
                                }

                            </TabPanel>
                            <TabPanel>
                                <SoilSettingsTab field={field} setField={setField} />
                            </TabPanel>
                            <TabPanel>
                                <MoistureLimitsTab field={field} setField={setField} />
</TabPanel>
                            </TabPanels>
                        <Button onClick={handlePrevious} isDisabled={tabIndex === 0} leftIcon={<ChevronLeftIcon />} mr={2}>
                            <Trans>Prev</Trans>
                        </Button>
                        <Button onClick={handleNext} isDisabled={tabIndex === 2} rightIcon={<ChevronRightIcon />} mr={2}>
                            <Trans>Next</Trans>
                        </Button>
                        <Button colorScheme="teal" onClick={handleSave}>
                            <Trans>Save</Trans>
                        </Button>
                    </Tabs>
                </VStack>
            </Box>
            </Container>
        </Navbar>
    );
}

export default AddModifyField;