import { useEffect, useState, useRef } from 'react';
import {
    Box,
    Heading,
    useToast,
    Button,
    FormControl,
    FormLabel,
    Input,
    useColorModeValue,
    Center,
    VStack,
    Text,
    Stack,
    SimpleGrid,
} from "@chakra-ui/react";

import { AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, useDisclosure } from "@chakra-ui/react";

import { useParams, useNavigate } from 'react-router-dom';

import Navbar from '../components/Navbar';
import useAxios from "../utils/useAxios";
import { Trans } from "@lingui/react/macro";

interface ILocation {
    latitude: number;
    longitude: number;
    altitude: number | undefined | null;
    accuracy: number;
    altitudeAccuracy: number | undefined | null;
    heading: number | undefined | null;
    speed: number | undefined | null;
}

const EditDeviceView = () => {

    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    const [deviceName, setDeviceName] = useState("");
    const [deviceAddress, setDeviceAddress] = useState("");
    const [location, setLocation] = useState<ILocation>();
    const [SensorDepth, setSensorDepth] = useState<number|undefined>(undefined);
    const [locationIsLoading, setLocationIsLoading] = useState(false);
    const [showManualEntry, setShowManualEntry] = useState(false);
    const [manualLatitude, setManualLatitude] = useState('');
    const [manualLongitude, setManualLongitude] = useState('');

    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef<HTMLButtonElement | null>(null);

    const toast = useToast();
    const api = useAxios();

    const getLocation = () => {

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(success, error, {
                enableHighAccuracy: true,
                timeout: 10000,
                maximumAge: 0
            });
            setLocationIsLoading(true);
        } else {
            console.log("Geolocation not supported");
            toast({
                title: <Trans>Geolocation not supported</Trans>,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }

        function success(position: GeolocationPosition) {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            const altitude = position.coords.altitude;
            const accuracy = position.coords.accuracy;
            const altitudeAccuracy = position.coords.altitudeAccuracy;
            const heading = position.coords.heading;
            const speed = position.coords.speed;

            setLocation({
                latitude: latitude,
                longitude: longitude,
                altitude: altitude,
                accuracy: accuracy,
                altitudeAccuracy: altitudeAccuracy,
                heading: heading,
                speed: speed,
            });
            setLocationIsLoading(false);
        }

        function error() {
            console.log("Unable to retrieve your location");
            toast({
                title: <Trans>Unable to retrieve your location</Trans>,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            setLocationIsLoading(false);
        }
    };


    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        try {

            let lat = location?.latitude;
            let long = location?.longitude;
            let accuracy = location?.accuracy;

            if (showManualEntry) {

                if (manualLatitude === '' || manualLongitude === '') {
                    toast({
                        title: <Trans>Please enter both latitude and longitude</Trans>,
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                    });
                    return;
                }

                lat = parseFloat(manualLatitude);
                long = parseFloat(manualLongitude);
                accuracy = 0;
            }

            const response = await api.post('/frontend/devices/associate/', {
                device_address: deviceAddress,
                device_name: deviceName,
                latitude: lat,
                longitude: long,
                accuracy: accuracy,
                altitude: location?.altitude,
                altitude_accuracy: location?.altitudeAccuracy,
                sensor_depth: SensorDepth,
            });
            // If the response is 201, the device was added successfully
            // If the response is 200, the device was modified successfully
            if (response.status === 201) {
                console.log("Device Added Successfully");
                toast({
                    title: <Trans>Device Added Successfully</Trans>,
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                });
            }
            if (response.status === 200) {
                console.log("Device Modified Successfully");
                toast({
                    title: <Trans>Device Modified Successfully</Trans>,
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                });
            }
            navigate(-1);

        } catch {
            toast({
                title: <Trans>Error Adding Device</Trans>,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };
    
    const fetchData = async () => {
        try {
            const response = await api.get("frontend/user-devices/" + id + "/");
            console.log(response.data);
            setDeviceName(response.data.device_name);
            setDeviceAddress(response.data.device_address);
            setSensorDepth(response.data.sensor_depth);

            if (response.data.latitude && response.data.longitude) {
                setLocation({
                    latitude: response.data.latitude,
                    longitude: response.data.longitude,
                    accuracy: response.data.accuracy,
                    altitude: response.data.altitude,
                    altitudeAccuracy: response.data.altitude_accuracy,
                    heading: response.data.heading,
                    speed: response.data.speed,
                });
            }
        } catch {
            // Toast
            toast({
                title: <Trans>Error fetching data</Trans>,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleDeleteDevice = () => {
        onOpen();
    };
    
    const confirmDelete = () => {
        api.delete("frontend/user-devices/" + id + "/")
            .then(() => {
                toast({
                    title: <Trans>Device deleted</Trans>,
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                });
            })
            .catch(error => {
                toast({
                    title: <Trans>Error deleting device</Trans>,
                    description: error.message,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            });
            onClose();

            // Redirect to front page
            navigate('/');
    }

    return (
        <>
        <Navbar>
            <Center>

                <Box
                    m={{ base: 2, sm: 4, md: 6 }}
                    p={5}
                    bg={useColorModeValue('white', 'gray.900')}
                    boxShadow="lg"
                    borderRadius="md"
                    width={{ base: "100%", md: 600 }}
                >
                    <SimpleGrid minChildWidth='40'>
                        <Heading mb={{ base: 2, md: 4 }}><Trans>Edit Device</Trans></Heading>

                    </SimpleGrid>

                    <form onSubmit={handleSubmit}>
                        <FormControl id="deviceAddress" isRequired>
                            <FormControl id="deviceName" isRequired={false}>
                                
                                <FormLabel mt="2"><Trans>Device Name</Trans></FormLabel>
                                <Input
                                    type="text"
                                    value={deviceName}
                                    isRequired={false}
                                    onChange={(e) => setDeviceName(e.target.value)}
                                    placeholder='e.g. "My Device"'
                                />
                            </FormControl>
                            <FormControl id="SensorDepth" isRequired={false}>
                            <FormLabel><Trans>Sensor Depth (cm)</Trans></FormLabel>
                            <Input
                                type="number"
                                required={false}
                                value={SensorDepth}
                                onChange={(e) => setSensorDepth(parseInt(e.target.value))}
                                mb={2}
                                placeholder='e.g. "30"'
                            />

                        </FormControl>
                        </FormControl>
                        <VStack align="start">
                        <Stack direction={{ base: 'column'}}>
                                <Button mt={4} colorScheme="teal" onClick={getLocation} variant={"outline"} isLoading={locationIsLoading} disabled={showManualEntry}>
                                    <Trans>Register GPS location</Trans>
                                </Button>
                                {
                                    location && !showManualEntry ?
                                        <>
                                            <Text><Trans>Latitude:</Trans> {location.latitude}</Text>
                                            <Text><Trans>Longitude:</Trans> {location.longitude}</Text>
                                            {location.accuracy ? <Text><Trans>Accuracy:</Trans> {location.accuracy.toFixed(0)}m </Text> : null}
                                        </> : null
                                }
                                <Text><Trans>or</Trans></Text>
                                <Button mt={4} colorScheme="teal" variant={"outline"} onClick={() => setShowManualEntry(!showManualEntry)}>
                                    <Trans>Enter coordinates manually</Trans>
                                </Button>
                                {showManualEntry && (
                                <>
                                    <FormControl id="manualLatitude" isRequired={showManualEntry}>
                                        <FormLabel><Trans>Manual Latitude</Trans></FormLabel>
                                        <Input
                                            type="number"
                                            step="any"
                                            onChange={(e) => setManualLatitude(e.target.value)}
                                            placeholder="E.g. 51.507422"
                                        />
                                    </FormControl>
                                    <FormControl id="manualLongitude" isRequired={showManualEntry}>
                                        <FormLabel><Trans>Manual Longitude</Trans></FormLabel>
                                        <Input
                                            type="number"
                                            step="any"
                                            onChange={(e) => setManualLongitude(e.target.value)}
                                            placeholder="E.g. 12.127887"
                                        />
                                    </FormControl>
                                </>
                            )}
                            </Stack>
                            <Button mt={6} colorScheme="teal" type="submit">
                                <Trans>Save Changes</Trans>
                            </Button>
                            <Button onClick={() => handleDeleteDevice()} variant="outline" colorScheme='red'><Trans>Delete</Trans></Button>
                        </VStack>
                    </form>
                </Box>
            </Center>
        </Navbar >
        <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
    >
        <AlertDialogOverlay>
            <AlertDialogContent>
                <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                    <Trans>Delete User Device</Trans>
                </AlertDialogHeader>
    
                <AlertDialogBody>
                    <Trans>Are you sure? You can't undo this action afterwards.</Trans>
                </AlertDialogBody>
    
                <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button colorScheme='red' onClick={confirmDelete} ml={3}>
                        <Trans>Delete</Trans>
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialogOverlay>
    </AlertDialog>
    </>
    )
};

export default EditDeviceView;