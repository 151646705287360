import { useEffect, useState } from 'react';
import {
    Box,
    Heading,
    useToast,
    Button,
    FormControl,
    FormLabel,
    Input,
    useColorModeValue,
    Center,
    VStack,
    Text,
    Stack,
    SimpleGrid,
    Spacer,
    HStack,
    Divider
} from "@chakra-ui/react";
import { QrReader } from 'react-qr-reader';

import { HiQrcode } from 'react-icons/hi';

import Navbar from '../components/Navbar';
import useAxios from "../utils/useAxios";
import { Trans } from "@lingui/react/macro";
import SmallMapWidget from './SmallMapWidget';

interface ILocation {
    latitude: number;
    longitude: number;
    altitude: number | undefined | null;
    accuracy: number;
    altitudeAccuracy: number | undefined | null;
    heading: number | undefined | null;
    speed: number | undefined | null;
}

const QrCodeAddress = ({ setDeviceAddress, setQRscanVisible }: any) => {
    const [data, setData] = useState('');

    useEffect(() => {
        if (data) {
            setDeviceAddress(data);
            setQRscanVisible(false);
        }
    }, [data]);

    return (
        <>
            <QrReader
                onResult={(result: any, error: any) => {
                    if (!!result) {
                        // Validate that the QR code is a valid device address
                        // It can only be numbers and letters
                        const regex = /^[a-zA-Z0-9]*$/;
                        if (!regex.test(result)) {
                            console.log('Invalid QR code');
                            return;
                        }

                        console.log('Result', result);
                        setData(result);
                    }
                    if (!!error) {
                        console.log('Error scanning QR code', error);
                    }
                }}
                constraints={{
                    facingMode: "environment"
                }}
                scanDelay={100}
            />
        </>
    );
};

const AddDeviceView = () => {
    const [deviceAddress, setDeviceAddress] = useState("");
    const [deviceName, setDeviceName] = useState("");
    const [location, setLocation] = useState<ILocation>();
    const [SensorDepth, setSensorDepth] = useState<number|undefined>(undefined);
    const [QRscanVisible, setQRscanVisible] = useState(false);
    const [locationIsLoading, setLocationIsLoading] = useState(false);
    

    const toast = useToast();
    const api = useAxios();

    const [isMapOpen, setIsMapOpen] = useState(false)

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            const response = await api.post('/frontend/devices/associate/', {
                device_address: deviceAddress,
                device_name: deviceName,
                latitude: location?.latitude,
                longitude: location?.longitude,
                accuracy: location?.accuracy,
                altitude: location?.altitude,
                altitude_accuracy: location?.altitudeAccuracy,
                sensor_depth: SensorDepth,
            });
            // If the response is 201, the device was added successfully
            // If the response is 200, the device was modified successfully
            if (response.status === 201) {
                console.log("Device Added Successfully");
                toast({
                    title: <Trans>Device Added Successfully</Trans>,
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                });
            }
            if (response.status === 200) {
                console.log("Device Modified Successfully");
                toast({
                    title: <Trans>Device Modified Successfully</Trans>,
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                });
            }

            setDeviceAddress("");
            setDeviceName("");
        } catch {
            toast({
                title: <Trans>Error Adding Device</Trans>,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const getLocation = () => {

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(success, error, {
                enableHighAccuracy: true,
                timeout: 10000,
                maximumAge: 0
            });
            setLocationIsLoading(true);
        } else {
            console.log("Geolocation not supported");
            toast({
                title: <Trans>Geolocation not supported</Trans>,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }

        function success(position: GeolocationPosition) {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            const altitude = position.coords.altitude;
            const accuracy = position.coords.accuracy;
            const altitudeAccuracy = position.coords.altitudeAccuracy;
            const heading = position.coords.heading;
            const speed = position.coords.speed;

            setLocation({
                latitude: latitude,
                longitude: longitude,
                altitude: altitude,
                accuracy: accuracy,
                altitudeAccuracy: altitudeAccuracy,
                heading: heading,
                speed: speed,
            });
            setLocationIsLoading(false);
        }

        function error() {
            console.log("Unable to retrieve your location");
            toast({
                title: <Trans>Unable to retrieve your location</Trans>,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            setLocationIsLoading(false);
        }
    };

    return (
        <Navbar>
            <Center>

                <Box
                    m={{ base: 2, sm: 4, md: 6 }}
                    p={5}
                    bg={useColorModeValue('white', 'gray.900')}
                    boxShadow="lg"
                    borderRadius="md"
                    width={{ base: "100%", md: 600 }}
                >
                    <SimpleGrid minChildWidth='40'>
                        <Heading mb={{ base: 2, md: 4 }}><Trans>Add Device</Trans></Heading>

                        <Button mb={1} colorScheme="teal" variant={"outline"} onClick={() => setQRscanVisible(!QRscanVisible)} leftIcon={<HiQrcode />}>
                            <Trans>Scan QR code</Trans>
                        </Button>
                    </SimpleGrid>
                    {QRscanVisible && <QrCodeAddress setDeviceAddress={setDeviceAddress} setQRscanVisible={setQRscanVisible} />}

                    <form onSubmit={handleSubmit}>
                        <FormControl id="deviceAddress" isRequired>
                            <FormControl id="deviceName" isRequired={false}>
                                
                                <FormLabel mt="2"><Trans>Device Name</Trans></FormLabel>
                                <Input
                                    type="text"
                                    value={deviceName}
                                    isRequired={false}
                                    onChange={(e) => setDeviceName(e.target.value)}
                                    placeholder='e.g. "My Device"'
                                />
                            </FormControl>
                            <FormLabel mt="2"><Trans>Device Address</Trans></FormLabel>
                            <Input
                                type="text"
                                value={deviceAddress}
                                required={true}
                                onChange={(e) => setDeviceAddress(e.target.value)}
                                mb={2}
                                placeholder='e.g. "123456789abcdef"'
                            />
                            <FormControl id="SensorDepth" isRequired={false}>
                            <FormLabel><Trans>Sensor Depth (cm)</Trans></FormLabel>
                            <Input
                                type="number"
                                required={false}
                                onChange={(e) => setSensorDepth(parseInt(e.target.value))}
                                mb={2}
                                placeholder='e.g. "30"'
                            />

                        </FormControl>
                        </FormControl>
                        <VStack align="start" spacing={4}>
                            <HStack spacing={5} align="center" mt={4}>
                                <Button colorScheme="teal" onClick={getLocation} variant={"outline"} isLoading={locationIsLoading}>
                                    <Trans>Register location</Trans>
                                </Button>

                                <Divider orientation="vertical" height="24px" />

                                <Button colorScheme="teal" variant={"outline"} onClick={() => setIsMapOpen(true)}>
                                  Pick on map
                                </Button >
                            </HStack>

                                {location ? (
                                    <HStack spacing={3}>
                                        <Text><Trans>Latitude:</Trans> {location.latitude}</Text>
                                        <Text><Trans>Longitude:</Trans> {location.longitude}</Text>
                                        <Text><Trans>Accuracy:</Trans> {location.accuracy.toFixed(0)}m</Text>
                                    </HStack>
                                ) : null}

                            
                            <Spacer />
                            <Button mt={4} colorScheme="teal" type="submit">
                                <Trans>Add Device</Trans>
                            </Button>
                        </VStack>
                    </form>
                </Box>

            </Center>

            <SmallMapWidget
                isOpen={isMapOpen}
                onClose={() => setIsMapOpen(false)}
                onSelect={(lat, lng) => {
                    setLocation({
                        latitude: lat,
                        longitude: lng,
                        altitude: null,
                        accuracy: 0,
                        altitudeAccuracy: null,
                        heading: null,
                        speed: null
                    });
                }}
            />
        </Navbar >
    )
};

export default AddDeviceView;