import { useState } from 'react';
import { Box, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from "@chakra-ui/react";
import Map, { NavigationControl, Marker } from 'react-map-gl';

interface MapPickerProps {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (latitude: number, longitude: number) => void;
}

const SmallMapWidget = ({ isOpen, onClose, onSelect }: MapPickerProps) => {
  const [viewState, setViewState] = useState({
    longitude: 10,
    latitude: 50,
    zoom: 4,
    bearing: 0,
    pitch: 0,
  });
  const [selectedPosition, setSelectedPosition] = useState<{ longitude: number; latitude: number } | null>(null);

  const handleMapClick = (evt: any) => {
    const { lng, lat } = evt.lngLat;
    setSelectedPosition({ longitude: lng, latitude: lat });
  };

  const handleConfirm = () => {
    if (selectedPosition) {
      onSelect(selectedPosition.latitude, selectedPosition.longitude);
      onClose();
    } else {
      console.log("No location selected");
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select Location on Map</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box width="100%" height="500px">
            <Map
              mapLib={import('mapbox-gl')}
              initialViewState={viewState}
              onMove={(evt) => setViewState(evt.viewState)}
              onClick={handleMapClick}
              mapStyle="mapbox://styles/mapbox/streets-v11"
              mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
              style={{ width: "100%", height: "100%" }}
            >
              <NavigationControl />
              {selectedPosition && (
                <Marker
                  longitude={selectedPosition.longitude}
                  latitude={selectedPosition.latitude}
                  anchor="bottom"
                >
                  <div style={{ fontSize: '24px', color: 'red' }}>📍</div>
                </Marker>
              )}
            </Map>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" onClick={onClose}>Cancel</Button>
          <Button
            colorScheme="teal"
            onClick={handleConfirm}
            disabled={!selectedPosition}
            ml={3}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SmallMapWidget;