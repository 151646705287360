import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, SimpleGrid, Stack, Flex, Spacer, VStack, Heading, useToast, Container, useColorModeValue, HStack, StackDivider, Skeleton } from '@chakra-ui/react';
import useAxios from '../../utils/useAxios';
import Navbar from '../../components/Navbar';
import { Trans } from "@lingui/react/macro";

interface Field {
  id: number;
  user: number;
  field_name: string;
  area: {
    [key: string]: any;
  };
}

const Fields = () => {
  const api = useAxios();
  const toast = useToast();
  const navigate = useNavigate();
  const [fields, setFields] = useState<Field[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('agtech/fields/');
        setFields(response.data);
        setIsLoading(false);
      } catch (error: any) {
        if (error.response.status === 401) {
          return;
        }
        toast({
          title: <Trans>Error fetching data</Trans>,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    };
    fetchData();
  }, []);

  const handleEdit = (id: number) => {
    navigate(`/fields/edit/${id}`);
  };

  const handleView = (id: number) => {
    navigate(`/fields/view/${id}`);
  };

  return (
    <Navbar>
      <Skeleton isLoaded={!isLoading}>
        <Container p={4} bg={useColorModeValue('white', 'gray.900')} boxShadow="lg" borderRadius="md" mt={4} maxWidth="xl">
          <Heading as="h1" size="2xl" textAlign="center" mb={4}>
            <Trans>Fields</Trans>
          </Heading>
          <Button colorScheme="teal" variant="outline" onClick={() => navigate('/fields/add')} mb={4}>
            <Trans>Add New Field</Trans>
          </Button>
          <VStack spacing={4} align="stretch" divider={<StackDivider borderColor="gray.200" />}>
            {fields.map((field) => (
              <Box key={field.id}>
                <Flex m={2}>
                  <Box>
                    <Heading as="h3" size="lg" mx={2}>
                      {field.field_name}
                    </Heading>
                  </Box>
                  <Spacer />
                  <Stack direction={{ base: 'column', md: 'row' }}>
                    <Button colorScheme="teal" variant="solid" size="sm" width="20" onClick={() => handleView(field.id)} m={0}>
                      <Trans>View</Trans>
                    </Button>
                    <Button colorScheme="teal" variant="outline" size="sm" width="20" onClick={() => handleEdit(field.id)} m={0}>
                      <Trans>Edit</Trans>
                    </Button>
                  </Stack>
                </Flex>
              </Box>
            ))}
          </VStack>
        </Container>
      </Skeleton>
    </Navbar>
  );
};

export default Fields;