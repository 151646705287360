import Navbar from '../components/Navbar';
import useAxios from "../utils/useAxios";
import { useState, useEffect } from 'react';
import { Center, SimpleGrid, useToast } from "@chakra-ui/react";
import { Container, Box, VStack, StackDivider, Spacer, useColorModeValue, Button, Stack, Heading, Text, Radio, RadioGroup, Skeleton, Show } from "@chakra-ui/react";
import { Link, useParams } from 'react-router-dom';
import { DataDump } from '../components/DeviceTile';
import DevicePlot from '../components/DevicePlot';
import { IDevice } from './DeviceOverview';
import { DeviceMap } from '../components/DeviceMap';
import { Trans } from "@lingui/react/macro";


const DeviceViewer = () => {
    const api = useAxios();
    const toast = useToast();

    const { id } = useParams<{ id: string }>();

    const [isLoading, setIsLoading] = useState(true);
    const [device, setDevice] = useState<IDevice | undefined>(undefined);

    const box_color = useColorModeValue('white', 'gray.900');
    const heading_color = useColorModeValue('black', 'gray.200');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get("frontend/devices/current-data/" + id + "/");
                console.log(response.data);
                setDevice(response.data);

                setIsLoading(false);
            } catch {
                setDevice(undefined);
                setIsLoading(false);
                // Toast
                toast({
                    title: <Trans>Error fetching data</Trans>,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        };
        fetchData();
    }, []);


    return (
        <Navbar>
            <VStack
                mt={2}
                align="stretch"
            >
                <SimpleGrid minChildWidth="350px" spacing="2" >
                    <Box padding="5" bg={box_color} boxShadow="md" borderRadius="md" m={1}>
                        <Stack direction={{ base: "column", sm: "row" }} mb={4}>
                            <Heading as="h2" size="lg" mb={4} color={heading_color}>
                                {device?.device_name ? device.device_name : <Trans>Unnamed device</Trans>}
                            </Heading>
                            <Spacer />
                            <Button as={Link} to={"/devices/" + id + "/edit"} colorScheme="teal" variant="outline" size="sm" mb={4}
                            maxWidth={100}
                            >
                                <Trans>Edit</Trans>
                            </Button>
                        </Stack>
                        <Heading size="sm" mb={2}><Trans>Current Data:</Trans></Heading>
                        <DataDump device={device} />
                    </Box>
                    <Show above="lg">
                        <Box padding="5" bg={box_color} boxShadow="md" borderRadius="md" m={1} height={300}>
                            <DeviceMap device={device} />
                        </Box>
                    </Show>
                </SimpleGrid>
                <Center>
                    <Box padding="5" bg={box_color} boxShadow="md" borderRadius="md" m={1} width={{ base: "100%" }} maxWidth={"1300"} alignContent={"center"}>
                        <DevicePlot />
                    </Box>

                </Center>
                <Show below="lg">
                    <Box padding="5" bg={box_color} boxShadow="md" borderRadius="md" m={1} height={300}>
                        <DeviceMap device={device} />
                    </Box>
                </Show>
            </VStack>
        </Navbar >
    )
};

export default DeviceViewer;
