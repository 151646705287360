import { useEffect, useState } from "react";
import {
  Box,
  Container,
  Heading,
  useColorModeValue,
  Skeleton,
  Link,
  Flex,
  Text
} from "@chakra-ui/react";
import { Line } from "react-chartjs-2";
import { useParams } from "react-router-dom";
import Navbar from "../../components/Navbar";
import useAxios from "../../utils/useAxios";
import WheatherForecastTableData from "./FieldWheatherForecastWidget";
import { Link as RouterLink } from "react-router-dom";
import { Trans } from "@lingui/react/macro";

interface WeatherData {
  hourly: {
    time: string[];
    temperature_2m: number[];
    et0_fao_evapotranspiration: number[];
    precipitation : number[];
    soil_temperature_6cm: number[];
    soil_temperature_18cm: number[];
    soil_temperature_54cm: number[];
  };
}

const AdditionalInformationWidget = () => {
  const { id } = useParams<{ id: string }>();
  const [weatherData, setWeatherData] = useState<WeatherData | null>(null);
  const api = useAxios();
  const bgColor = useColorModeValue("white", "gray.900");

  useEffect(() => {
    if (!id) return;
    const fetchData = async () => {
      try {
        const response = await api.get(`/agtech/weather-forecast/hourly/${id}/`);
        setWeatherData(response.data);
      } catch (error) {
        console.error("Error fetching weather data:", error);
      }
    };
    fetchData();
  }, [id]);

  if (!weatherData)
    return (
      <Navbar>
        <Skeleton height="400px" />
      </Navbar>
    );

  const timeData = weatherData.hourly.time.map((timeStr) => new Date(timeStr));
  const formattedTime = timeData.map((date) =>
    date.toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit" })
  );

  const chartData = {
    labels: formattedTime,
    datasets: [
      {
        label: "Temperature (°C)",
        data: weatherData.hourly.temperature_2m,
        borderColor: "#ff7300",
        backgroundColor: "rgba(255, 115, 0, 0.2)",
        fill: false,
        tension: 0.4,
        pointRadius: 0,
        pointHoverRadius: 0,
        yAxisID: "y-axis-temp",
      },
      {
        type: "bar",
        label: "Evapotranspiration (mm)",
        data: weatherData.hourly.et0_fao_evapotranspiration,
        borderColor: "rgba(128, 240, 0, 0.99)",
        backgroundColor: "rgba(13, 204, 54, 0.5)",
        yAxisID: "y-axis-mm",
        hidden: true,
      },
      {
        type: "bar",
        label: "Precipitation (mm)",
        data: weatherData.hourly.precipitation,
        borderColor: "rgba(0, 0, 240, 0.99)",
        backgroundColor: "rgba(0, 0, 240, 0.5)",
        yAxisID: "y-axis-mm",
      },
      {
        label: "Soil temperature 6cm",
        data: weatherData.hourly.soil_temperature_6cm,
        borderColor: "rgba(216, 113, 113, 0.97)",
        backgroundColor: "rgba(216, 113, 113, 0.97)",
        fill: false,
        tension: 0.4,
        yAxisID: "y-axis-temp",
        pointRadius: 0,
        pointHoverRadius: 0,
        hidden: true,
        
      },
      {
        label: "Soil temperature 18cm",
        data: weatherData.hourly.soil_temperature_18cm,
        borderColor: "rgba(214, 0, 0, 0.99)",
        backgroundColor: "rgba(214, 0, 0, 0.99)",
        fill: false,
        tension: 0.4,
        yAxisID: "y-axis-temp",
        pointRadius: 0,
        pointHoverRadius: 0
      },
      {
        label: "Soil temperature 54cm",
        data: weatherData.hourly.soil_temperature_54cm,
        borderColor: "rgb(131, 0, 0)",
        backgroundColor: "rgb(131, 0, 0)",
        fill: false,
        tension: 0.4,
        yAxisID: "y-axis-temp",
        pointRadius: 0,
        pointHoverRadius: 0,
        hidden: true,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 5,
      }
    },
    scales: {
      xAxes: [
        {
          ticks: {
            maxRotation: 45,
            minRotation: 0,
          },
        },
      ],
      yAxes: [
        {
          id: "y-axis-temp",
          type: "linear",
          position: "left",
          scaleLabel: {
            display: true,
            labelString: "Temperature (°C)",
          },
        },
        {
          id: "y-axis-mm",
          type: "linear",
          position: "right",
          scaleLabel: {
            display: true,
            labelString: "Evapotranspiration & Precipitation (mm)",
          },
          ticks: {
            suggestedMin: 0,
            suggestedMax: 5,
          },
        },
      ],
    },
    tooltips: {
      mode: 'index',
      intersect: false,
      position: 'nearest',
      callbacks: {
        label: function(tooltipItem: any, data: any){
          let label = data.datasets[tooltipItem.datasetIndex].label || '';
          if (label) {
            label += ': ';
          }
          label += tooltipItem.yLabel.toFixed(1);
          return label;
        }
      }
    }
  };

  const daySeparatorPlugin = {
    afterDraw: (chart: any) => {
      const ctx = chart.chart.ctx;
      const xAxis = chart.scales["x-axis-0"];
      if (!xAxis) return;
      for (let i = 1; i < timeData.length; i++) {
        const currentDay = timeData[i].getDate();
        const previousDay = timeData[i - 1].getDate();
        if (currentDay !== previousDay) {
          const x = xAxis.getPixelForTick(i);
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, chart.chartArea.top);
          ctx.lineTo(x, chart.chartArea.bottom);
          ctx.lineWidth = 1;
          ctx.strokeStyle = "rgba(0, 0, 0, 0.5)";
          ctx.stroke();
          const dayStr = ('0' + timeData[i].getDate()).slice(-2);
          const monthStr = ('0' + (timeData[i].getMonth() + 1)).slice(-2);
          const dateString = `${dayStr}-${monthStr}`;
          ctx.fillStyle = "rgba(0, 0, 0, 0.8)";
          ctx.font = "12px sans-serif";
          ctx.textAlign = "center";
          ctx.fillText(dateString, x, chart.chartArea.top + 15);
          ctx.restore();
        }
      }
    },
  };

  return (
    <Navbar>
      <Container maxW={{ base: "100%", "2xl": "80%" }} p={4} bg={bgColor} boxShadow="lg" borderRadius="md" mt={4}>
        <Flex alignItems="center" justifyContent="space-between">
          <Heading size="lg" mb={4}>
            <Trans>14 Days Weather Forecast</Trans>
          </Heading>
          <Link as={RouterLink} to={`/fields/view/${id}`}>
            <Trans>Back to Field Menu</Trans>
          </Link>
        </Flex>
        <Text>
          <Trans>Press the buttons below to show or hide data</Trans>
        </Text>
        <Box position="relative" width="100%" height="500px">
          <Line data={chartData} options={chartOptions} plugins={[daySeparatorPlugin]}/>
        </Box>
      </Container>
      <Container maxW={{ base: "100%", "2xl": "80%" }} p={4} bg={bgColor} boxShadow="lg" borderRadius="md" mt={4}>

        <WheatherForecastTableData id={id} forecast_days={15} />

      </Container>
    </Navbar>
  );
};

export default AdditionalInformationWidget;