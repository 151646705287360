import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import {
    Box,
    Heading,
    useToast,
    Button,
    FormControl,
    FormLabel,
    Input,
    useColorModeValue,
    Center,
    VStack,
    Text,
    Select,
    Stack,
    SimpleGrid,
} from "@chakra-ui/react";

import Navbar from '../components/Navbar';
import useAxios from "../utils/useAxios";
import { convertToObject } from 'typescript';
import { Trans } from "@lingui/react/macro";

interface IAlarm {
    id: number | undefined;
    device: number;
    device_name: string;
    alarm_type: string;
    trigger_value: number;
    trigger_below_or_above: boolean;
}

interface IDevice {
    id: number;
    device_name: string;
    device_type: string;
}

// Enum for alarm type
enum DRAGINOALARMTYPES {
    MOISTURE = 'MOISTURE',
    SOIL_TEMPERATURE = 'SOIL_TEMPERATURE',
}

enum AUTOPLANTALARMTYPES {
    MOISTURE = 'MOISTURE',
    TEMPERATURE = 'TEMPERATURE',
    HUMIDITY = 'HUMIDITY',
}

enum ENVIROMONALARMTYPES {
    TEMPERATURE = 'TEMPERATURE',
    HUMIDITY = 'HUMIDITY',
}

const AddEditAlarmView = () => {
    const [alarm, setAlarm] = useState<IAlarm | null>(null);

    const [devices, setDevices] = useState<IDevice[] | undefined>(undefined);

    const [alarmTypes, setAlarmTypes] = useState<string[]>([]);

    const currentAlarmId = useParams<{ id: string }>().id;

    const api = useAxios();

    const toast = useToast();

    const navigate = useNavigate();

    const boxColor = useColorModeValue('white', 'gray.900');


    useEffect(() => {
        if (currentAlarmId) {
            api.get(`/frontend/alarms/${currentAlarmId}/`)
                .then((response) => {
                    console.log('Alarm fetched', response.data)
                    setAlarm(response.data);
                })
                .catch((error) => {
                    console.error('Error fetching alarm', error);
                    toast({
                        title: <Trans>Error fetching alarm</Trans>,
                        description: error.message,
                        status: 'error',
                        isClosable: true,
                    });
                });
        
        }
        // Get devices
        api.get('/frontend/user-devices/')
        .then((response) => {
            console.log('Devices fetched', response.data)
            setDevices(response.data);
        })
        .catch((error) => {
            console.error('Error fetching devices', error);
            toast({
                title: <Trans>Error fetching devices</Trans>,
                description: error.message,
                status: 'error',
                isClosable: true,
            });
            });
    }, []);

    const handleDeviceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedDeviceId = event.target.value;
        setAlarm((prevAlarm): IAlarm | null => {
            if (prevAlarm === null) {
                return {
                    device: Number(selectedDeviceId),
                    id: undefined, // Assuming 'id' can be undefined in IAlarm
                    device_name: '', // Placeholder or default value
                    // Add the missing properties with appropriate default values
                    alarm_type: '', // Placeholder or default value
                    trigger_value: 0, // Assuming a numeric value; adjust as necessary
                    trigger_below_or_above: true, // Assuming a boolean; adjust as necessary
                };
            }
    
            return {
                ...prevAlarm,
                device: Number(selectedDeviceId),
                device_name: devices?.find((device) => device.id === Number(selectedDeviceId))?.device_name || '', // Placeholder or default value
            };
        });
    };

    const handleAlarmTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setAlarm((prevAlarm): IAlarm | null => {
          if (!prevAlarm) return null; // Handle case where prevAlarm is null
          return {
            ...prevAlarm,
            alarm_type: event.target.value,
          };
        });
      };

    const getAlarmTypesForDevice = (deviceId: number) => {
        // The alarm type options are based on the devices device_type
        // This function should return the appropriate alarm types based on the device type
        // Default to AUTOPLANTALARMTYPES if device type is not available

        const device = devices?.find((device) => device.id === deviceId);
        if (!device) {return []}
        switch (device.device_type) {
            case 'DRAGINO':
                return Object.values(DRAGINOALARMTYPES);
            case 'AUTOPLANT':
                return Object.values(AUTOPLANTALARMTYPES);
            case 'ENVIRONMENT':
                return Object.values(ENVIROMONALARMTYPES);
            default:
                return Object.values(AUTOPLANTALARMTYPES);
        }
    }

    useEffect(() => {
        if (alarm?.device) {
            setAlarmTypes(getAlarmTypesForDevice(alarm.device));
        }
    }, [alarm?.device, devices]);

    // Function to save or update an alarm
    const saveAlarm = (alarmData: IAlarm) => {
        const endpoint = currentAlarmId ? `/frontend/alarms/${currentAlarmId}/` : '/frontend/alarms/';
        const method = currentAlarmId ? 'put' : 'post';

        console.log('Saving alarm', alarmData);

        api[method](endpoint, alarmData)
            .then((response) => {
                // Handle success
                toast({
                    title: currentAlarmId ? <Trans>Alarm updated</Trans> : <Trans>Alarm created</Trans>,
                    description: currentAlarmId ? <Trans>The alarm has been updated successfully.</Trans> : <Trans>A new alarm has been created successfully.</Trans>,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                // Redirect the user or update the state here
                navigate('/alarms');
            })
            .catch((error) => {
                // Handle error
                toast({
                    title: <Trans>Error</Trans>,
                    description: <Trans>There was an error {currentAlarmId ? 'updating' : 'creating'} the alarm.</Trans>,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            });
    };

    const handleDeleteAlarm = () => {
        if (!currentAlarmId) {
            return;
        }

        api.delete(`/frontend/alarms/${currentAlarmId}/`)
            .then((response) => {
                // Handle success
                toast({
                    title: <Trans>Alarm deleted</Trans>,
                    description: <Trans>The alarm has been deleted successfully.</Trans>,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                // Redirect the user or update the state here
                navigate('/alarms');
            })
            .catch((error) => {
                // Handle error
                toast({
                    title: <Trans>Error</Trans>,
                    description: <Trans>There was an error deleting the alarm.</Trans>,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            });
    }

    return (
        <Navbar>
            <Center>
                <VStack>
                    <Box
                        bgColor={boxColor}
                        p={4}
                        m={2}
                        borderRadius={8}
                        boxShadow="md"
                    >
                    <Heading mb={2}>{currentAlarmId ? <Trans>Edit alarm</Trans> : <Trans>Add alarm</Trans>}</Heading>
                        <SimpleGrid columns={2} spacing={4}>
                        <FormControl id="device">
                                <FormLabel><Trans>Device</Trans></FormLabel>
                                <Select value={alarm?.device} onChange={handleDeviceChange} placeholder="Select device">
                                    {devices?.map((device) => (
                                        <option key={device.id} value={device.id}>
                                            {device.device_name || device.id}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl id="alarmType">
                            <FormLabel><Trans>Alarm type</Trans></FormLabel>
                                <Select value={alarm?.alarm_type} onChange={handleAlarmTypeChange} isDisabled={!alarm?.device} placeholder="Select alarm type">
                                    { alarm?.device &&
                                    alarmTypes.map((alarmType) => (
                                        <option key={alarmType} value={alarmType}>
                                            {alarmType}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl id="triggerBelowOrAbove">
                                <FormLabel><Trans>Trigger when value is</Trans></FormLabel>
                                <Select value={alarm?.trigger_below_or_above ? 'true' : 'false'}
                                onChange={(e) => setAlarm((prevAlarm) => {
                                    if (!prevAlarm) return null;
                                    return {
                                        ...prevAlarm,
                                        trigger_below_or_above: e.target.value === 'true',
                                    };
                                })}>
                                    <option value="true"><Trans>Below</Trans></option>
                                    <option value="false"><Trans>Above</Trans></option>
                                </Select>
                            </FormControl>
                            <FormControl id="triggerValue">
                                <FormLabel><Trans>Trigger value</Trans></FormLabel>
                                <Input
                                    type="number"
                                    onChange={(e) => setAlarm((prevAlarm) => {
                                    if (!prevAlarm) return null;
                                    return {
                                        ...prevAlarm,
                                        trigger_value: Number(e.target.value),
                                    };
                                    })}
                                    value={alarm?.trigger_value || ''} // Set the value to trigger_value or an empty string if undefined
                                />
                            </FormControl>
                        </SimpleGrid>
                        { alarm?.device && alarm?.alarm_type && !!alarm?.trigger_value && (
                            <Text mt={2}>
                                <Trans>
                                  {alarm?.device_name ? alarm.device_name : `Device ${alarm?.device}`} will trigger an alarm when {alarm?.alarm_type} is {alarm?.trigger_below_or_above ? 'BELOW' : 'ABOVE'} {alarm?.trigger_value}.
                                </Trans>
                            </Text>
                        )}

                        <Button
                            colorScheme="teal"
                            mt={4}
                            onClick={() => alarm && saveAlarm(alarm)}
                            isDisabled={!alarm?.device || !alarm?.alarm_type || !alarm?.trigger_value}
                        >
                            <Trans>Save</Trans>
                        </Button>
                        {currentAlarmId && (  
                            <Button
                                variant="outline"
                                colorScheme="red"
                                mt={4}
                                ml={6}
                                onClick={handleDeleteAlarm}><Trans>Delete Alarm</Trans></Button>
                        )}
                    </Box>
                </VStack>
            </Center>
        </Navbar>
    );
}

export default AddEditAlarmView;