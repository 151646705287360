import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box,
    Heading,
    useToast,
    Button,
    FormControl,
    FormLabel,
    Input,
    useColorModeValue,
    Center,
    VStack,
    Text,
    Select,
    SimpleGrid,
} from "@chakra-ui/react";
import { Trans } from "@lingui/react/macro";

import Navbar from '../components/Navbar';
import useAxios from "../utils/useAxios";

interface IFieldAlarm {
  id?: number;
  alarm_type: string | null;
  trigger_value: number | null;
  trigger_below_or_above: boolean;
  trigger_type: string | null;
  field: number | null;
}

const AddFieldAlarmView = () => {
    const { id: fieldId } = useParams<{ id: string }>();

    const [fieldAlarm, setFieldAlarm] = useState<IFieldAlarm>({
        alarm_type: null,
        trigger_value: null,
        trigger_below_or_above: false,
        trigger_type: null,
        field: fieldId ? Number(fieldId) : null,
    });

    const api = useAxios();
    const toast = useToast();
    const navigate = useNavigate();
    const boxColor = useColorModeValue('white', 'gray.900');

    const handleSave = () => {
        api.post('/frontend/field-alarms/', fieldAlarm)
            .then((response) => {
                toast({
                    title: <Trans>Field alarm created</Trans>,
                    description: <Trans>A new field alarm has been created successfully.</Trans>,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                navigate('/alarms');
            })
            .catch((error) => {
                console.error('Error creating field alarm', error);
                toast({
                    title: <Trans>Error creating field alarm</Trans>,
                    description: error.message, // unchanged dynamic text
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            });
    };

    return (
        <Navbar>
            <Center>
                <VStack>
                    <Box
                        bgColor={boxColor}
                        p={4}
                        m={2}
                        borderRadius={8}
                        boxShadow="md"
                    >
                        <Heading mb={2}>
                            <Trans>Add Field Alarm</Trans>
                        </Heading>

                        <SimpleGrid columns={2} spacing={4}>
                            <FormControl id="alarmType">
                                <FormLabel>
                                    <Trans>Alarm type</Trans>
                                </FormLabel>
                                <Select
                                    placeholder="Select alarm type"
                                    value={fieldAlarm.alarm_type || ''}
                                    onChange={(e) =>
                                        setFieldAlarm((prev) => ({
                                            ...prev,
                                            alarm_type: e.target.value,
                                        }))
                                    }
                                >
                                    <option value="MOISTURE">
                                        <Trans>MOISTURE</Trans>
                                    </option>
                                    <option value="TEMPERATURE">
                                        <Trans>TEMPERATURE</Trans>
                                    </option>
                                </Select>
                            </FormControl>

                            <FormControl id="triggerBelowOrAbove">
                                <FormLabel>
                                    <Trans>Trigger when value is</Trans>
                                </FormLabel>
                                <Select
                                    value={fieldAlarm.trigger_below_or_above ? 'true' : 'false'}
                                    onChange={(e) =>
                                        setFieldAlarm((prev) => ({
                                            ...prev,
                                            trigger_below_or_above: e.target.value === 'true',
                                        }))
                                    }
                                >
                                    <option value="true">
                                        <Trans>Below</Trans>
                                    </option>
                                    <option value="false">
                                        <Trans>Above</Trans>
                                    </option>
                                </Select>
                            </FormControl>

                            <FormControl id="triggerValue">
                                <FormLabel>
                                    <Trans>Trigger value</Trans>
                                </FormLabel>
                                <Input
                                    type="number"
                                    value={fieldAlarm.trigger_value ?? ''}
                                    onChange={(e) =>
                                        setFieldAlarm((prev) => ({
                                            ...prev,
                                            trigger_value: Number(e.target.value),
                                        }))
                                    }
                                />
                            </FormControl>

                            <FormControl id="triggerType">
                                <FormLabel>
                                    <Trans>Trigger type</Trans>
                                </FormLabel>
                                <Select
                                    placeholder="Select trigger type"
                                    value={fieldAlarm.trigger_type || ''}
                                    onChange={(e) =>
                                        setFieldAlarm((prev) => ({
                                            ...prev,
                                            trigger_type: e.target.value,
                                        }))
                                    }
                                >
                                    <option value="0">
                                        <Trans>Single device</Trans>
                                    </option>
                                    <option value="1">
                                        <Trans>Average</Trans>
                                    </option>
                                </Select>
                            </FormControl>
                        </SimpleGrid>

                        <Button
                            colorScheme="teal"
                            mt={4}
                            onClick={handleSave}
                            isDisabled={
                                !fieldAlarm.field || 
                                !fieldAlarm.alarm_type ||
                                fieldAlarm.trigger_value == null
                            }
                        >
                            <Trans>Save</Trans>
                        </Button>
                    </Box>
                </VStack>
            </Center>
        </Navbar>
    );
};

export default AddFieldAlarmView;
