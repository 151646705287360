import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box,
    Heading,
    useToast,
    Button,
    FormControl,
    FormLabel,
    Input,
    useColorModeValue,
    Center,
    VStack,
    Text,
    Select,
    Stack,
    SimpleGrid,
} from "@chakra-ui/react";
import { Trans } from "@lingui/react/macro";

import Navbar from '../components/Navbar';
import useAxios from "../utils/useAxios";

enum AlarmType {
  MOISTURE = 'MOISTURE',
  TEMPERATURE = 'TEMPERATURE',
  HUMIDITY = 'HUMIDITY',
  SOIL_TEMPERATURE = 'SOIL_TEMPERATURE',
  BATTERY_LEVEL = 'BATTERY_LEVEL',
}

enum TriggerType {
  SINGLE_DEVICE = 0,
  AVERAGE = 1,
}

interface IFieldAlarm {
  id?: number;
  alarm_type: string;
  trigger_value: number;
  trigger_below_or_above: boolean;
  trigger_type: number;
  field: number;
  field_name?: string;
  is_active?: boolean;
}

const EditFieldAlarmView = () => {
  const { id: fieldAlarmId } = useParams<{ id: string }>();

  const [fieldAlarm, setFieldAlarm] = useState<IFieldAlarm | null>(null);

  const api = useAxios();
  const toast = useToast();
  const navigate = useNavigate();
  const boxColor = useColorModeValue('white', 'gray.900');

  useEffect(() => {
    if (fieldAlarmId) {
      api.get(`/frontend/field-alarms/${fieldAlarmId}/`)
        .then((response) => {
          console.log('Field Alarm fetched', response.data);
          setFieldAlarm(response.data);
        })
        .catch((error) => {
          console.error('Error fetching field alarm', error);
          toast({
            title: <Trans>Error fetching field alarm</Trans>,
            description: error.message,
            status: 'error',
            isClosable: true,
          });
        });
    }
  }, [fieldAlarmId]);

  const handleSave = () => {
    if (!fieldAlarm) return;

    const endpoint = fieldAlarmId
      ? `/frontend/field-alarms/${fieldAlarmId}/`
      : '/frontend/field-alarms/';
    const method = fieldAlarmId ? 'put' : 'post';

    api[method](endpoint, fieldAlarm)
      .then(() => {
        toast({
          title: fieldAlarmId ? <Trans>Field Alarm updated</Trans> : <Trans>Field Alarm created</Trans>,
          description: <Trans>Operation successful.</Trans>,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        navigate('/alarms');
      })
      .catch((error) => {
        console.error('Error saving field alarm', error);
        toast({
          title: <Trans>Error</Trans>,
          description: fieldAlarmId ? <Trans>Error updating field alarm</Trans> : <Trans>Error creating field alarm</Trans>,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handleDelete = () => {
    if (!fieldAlarmId) return;

    api.delete(`/frontend/field-alarms/${fieldAlarmId}/`)
      .then(() => {
        toast({
          title: <Trans>Field Alarm deleted</Trans>,
          description: <Trans>The field alarm has been deleted successfully.</Trans>,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        navigate('/alarms');
      })
      .catch((error) => {
        console.error('Error deleting field alarm', error);
        toast({
          title: <Trans>Error deleting field alarm</Trans>,
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <Navbar>
      <Center>
        <VStack>
          <Box bgColor={boxColor} p={4} m={2} borderRadius={8} boxShadow="md">
            <Heading mb={2}>
              {fieldAlarmId ? <Trans>Edit Field Alarm</Trans> : <Trans>Add Field Alarm</Trans>}
            </Heading>

            <SimpleGrid columns={2} spacing={4}>
              <FormControl id="alarmType">
                <FormLabel><Trans>Alarm type</Trans></FormLabel>
                <Select
                  placeholder="Select alarm type"
                  value={fieldAlarm?.alarm_type ?? ''}
                  onChange={(e) =>
                    setFieldAlarm((prev) =>
                      prev ? { ...prev, alarm_type: e.target.value } : null
                    )
                  }
                >
                  {Object.values(AlarmType).map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl id="triggerBelowOrAbove">
                <FormLabel><Trans>Trigger when value is</Trans></FormLabel>
                <Select
                  value={fieldAlarm?.trigger_below_or_above ? 'true' : 'false'}
                  onChange={(e) =>
                    setFieldAlarm((prev) =>
                      prev ? { ...prev, trigger_below_or_above: e.target.value === 'true' } : null
                    )
                  }
                >
                  <option value="true"><Trans>Below</Trans></option>
                  <option value="false"><Trans>Above</Trans></option>
                </Select>
              </FormControl>

              <FormControl id="triggerValue">
                <FormLabel><Trans>Trigger value</Trans></FormLabel>
                <Input
                  type="number"
                  value={fieldAlarm?.trigger_value ?? ''}
                  onChange={(e) =>
                    setFieldAlarm((prev) =>
                      prev ? { ...prev, trigger_value: Number(e.target.value) } : null
                    )
                  }
                />
              </FormControl>

              <FormControl id="triggerType">
                <FormLabel><Trans>Trigger type</Trans></FormLabel>
                <Select
                  placeholder="Select trigger type"
                  value={fieldAlarm?.trigger_type ?? ''}
                  onChange={(e) =>
                    setFieldAlarm((prev) =>
                      prev ? { ...prev, trigger_type: Number(e.target.value) } : null
                    )
                  }
                >
                  <option value={TriggerType.SINGLE_DEVICE}><Trans>Single device</Trans></option>
                  <option value={TriggerType.AVERAGE}><Trans>Average</Trans></option>
                </Select>
              </FormControl>
            </SimpleGrid>

            {fieldAlarm?.alarm_type && fieldAlarm.trigger_value != null && (
              <Text mt={2}>
                <Trans>
                  This alarm will trigger when <strong>{fieldAlarm.alarm_type}</strong> is {fieldAlarm.trigger_below_or_above ? 'BELOW' : 'ABOVE'} {fieldAlarm.trigger_value}.
                </Trans>
              </Text>
            )}

            <Stack direction="row" spacing={4} mt={4}>
              <Button colorScheme="teal" onClick={handleSave} isDisabled={ !fieldAlarm?.alarm_type || fieldAlarm.trigger_value == null} >
                <Trans>Save</Trans>
              </Button>

              {fieldAlarmId && (
                <Button
                  variant="outline"
                  colorScheme="red"
                  onClick={handleDelete}
                >
                  <Trans>Delete Alarm</Trans>
                </Button>
              )}
            </Stack>
          </Box>
        </VStack>
      </Center>
    </Navbar>
  );
};

export default EditFieldAlarmView;
