import * as React from "react"
import { useState, useEffect } from "react"
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button, // <-- changed from IconButton to Button
} from "@chakra-ui/react"
import { ChevronDownIcon } from "@chakra-ui/icons"
import { i18n } from "@lingui/core" // replaced previous i18n import

const LOCAL_STORAGE_KEY = "app_language"

export const LanguageSelector: React.FC = () => {
  const [language, setLanguage] = useState<string>("en")

  useEffect(() => {
    const storedLang = localStorage.getItem(LOCAL_STORAGE_KEY)
    if (storedLang) {
      setLanguage(storedLang)
    }
  }, [])

  const handleChange = (lang: string) => {
    setLanguage(lang)
    localStorage.setItem(LOCAL_STORAGE_KEY, lang)
    i18n.activate(lang)
  }

  return (
    <Menu>
      <MenuButton
        as={Button}
        size="md"
        fontSize="lg"
        variant="ghost"
        color="current"
        marginLeft="2"
        rightIcon={<ChevronDownIcon />} 
        aria-label="Select language"
      >
        {language.toLocaleUpperCase()}
      </MenuButton>
      <MenuList>
        <MenuItem onClick={() => handleChange("en")}>EN</MenuItem>
        <MenuItem onClick={() => handleChange("es")}>ES</MenuItem>
      </MenuList>
    </Menu>
  )
}