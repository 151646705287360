import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import {
    Box,
    Heading,
    useToast,
    Button,
    useColorModeValue,
    Center,
    VStack,
    Text,
    Stack,
    SimpleGrid,
    Spacer,
    Flex,
    Container,
    Select,
    Divider,
} from "@chakra-ui/react";

import { CheckCircleIcon, WarningTwoIcon } from '@chakra-ui/icons';
import Navbar from '../components/Navbar';
import useAxios from "../utils/useAxios";
import { Trans } from "@lingui/react/macro";

interface IAlarm {
    id: number;
    is_active: boolean;
    device_is_offline: boolean;
    created_at: string;
    modified_at: string;
    sent_at: string | null;
    device: number;
    device_name?: string;
    alarm_type: string;
    trigger_value: number;
    trigger_below_or_above: boolean;
    field_id: number;
    field_name: string;
    trigger_type?: string;
}

interface IField {
    id: number;
    field_name: string;
    field_center_lat: number;
    field_center_lon: number;
    field_size: number;
}

const AlarmsView = () => {
    const [alarms, setAlarms] = useState<IAlarm[] | null>(null);
    const [originalAlarms, setOriginalAlarms] = useState<IAlarm[] | null>(null);
    const [fieldAlarms, setFieldAlarms] = useState<IAlarm[] | null>(null);
    const [fields, setFields] = useState<IField[]>([]);

    const axios = useAxios();
    const toast = useToast();
    const navigate = useNavigate();
    const boxColor = useColorModeValue('white', 'gray.900');

    const [sortBy, setSortBy] = useState<"created_at" | "modified_at" | "device_name">("created_at");

    const sortAlarms = (data: IAlarm[], field: "created_at" | "modified_at" | "device_name") => {
        return [...data].sort((a, b) => {
            if (field === "device_name") {
                return (a.device_name || "").localeCompare(b.device_name || "");
            } else {
                return new Date(b[field]).getTime() - new Date(a[field]).getTime();
            }
        });
    };

    useEffect(() => {
        axios.get('/frontend/field-alarms/')
            .then((response) => {
                console.log('Field alarms fetched', response.data);
                setFieldAlarms(response.data);
            })
            .catch((error) => {
                console.error('Error fetching field alarms', error);
                toast({
                    title: 'Error fetching field alarms',
                    description: error.message,
                    status: 'error',
                    isClosable: true,
                });
            });
    }, []);

    useEffect(() => {
        axios.get('/frontend/alarms/')
            .then((response) => {
                console.log('Alarms fetched', response.data);
                setOriginalAlarms(response.data);
            })
            .catch((error) => {
                console.error('Error fetching alarms', error);
                toast({
                    title: 'Error fetching alarms',
                    description: error.message,
                    status: 'error',
                    isClosable: true,
                });
            });
    }, []);

    useEffect(() => {
        axios.get('/agtech/fields/')
            .then((response) => {
                console.log('Fields fetched', response.data);
                setFields(response.data);
            })
            .catch((error) => {
                console.error('Error fetching fields', error);
                toast({
                    title: 'Error fetching fields',
                    description: error.message,
                    status: 'error',
                    isClosable: true,
                });
            });
    }, []);

    useEffect(() => {
        if (originalAlarms) {
            setAlarms(sortAlarms(originalAlarms, sortBy));
        }
    }, [originalAlarms, sortBy]);

    const handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSortBy(e.target.value as "created_at" | "modified_at" | "device_name");
    };

    const isFieldAlarm = (alarm: IAlarm) => {
        return alarm.trigger_type !== undefined;
    };

    const allDeviceAlarms = alarms || [];
    const allFieldAlarms = fieldAlarms || [];
    const allAlarms = [...allDeviceAlarms, ...allFieldAlarms];

    const fieldIds = fields.map(f => f.id);
    const unattachedAlarms = allAlarms.filter(a => !fieldIds.includes(a.field_id));

    return (
        <Navbar>
            <Box p={4}>
                <Container>
                    <Stack direction="row" align="center" spacing={4}>
                        <Heading as="h1" size="2xl">
                            <Trans>Alarms</Trans>
                        </Heading>
                        <Spacer />
                        <Stack direction="row" spacing={4} align="center">
                            <Button size="lg" colorScheme="teal" bg={boxColor} variant="outline" onClick={() => navigate('add')} minW="140px" flexShrink={0}>
                                <Trans>Add Alarm</Trans>
                            </Button>
                            <Select value={sortBy} onChange={handleSortChange} size="lg" minW="200px">
                                <option value="created_at"><Trans>Date Added</Trans></option>
                                <option value="modified_at"><Trans>Date Modified</Trans></option>
                                <option value="device_name"><Trans>Device Name</Trans></option>
                            </Select>
                        </Stack>
                    </Stack>
                    <Text mt={4} align="center">
                        <Trans>
                            Add or edit alarms. When an alarm is triggered, an email is sent to the user.
                            Updated every hour.
                        </Trans>
                    </Text>
                </Container>

                <Center>
                    <VStack spacing={4} mt={4} align="stretch">
                        {fields.map((field) => {
                            const deviceAlarmsForField = alarms?.filter(alarm => alarm.field_id === field.id) || [];
                            const pureFieldAlarms = fieldAlarms?.filter(alarm => alarm.field_id === field.id) || [];
                            const combinedAlarms = [...deviceAlarmsForField, ...pureFieldAlarms];

                            return (
                                <Box key={field.id} mb={6}>
                                    <Stack direction="row" align="center" p={4}>
                                        <Divider orientation='vertical' />
                                        <Heading size="md" mb={2} fontSize={{ base: 'xl', lg: '2xl' }}>
                                            {field.field_name}
                                        </Heading>
                                        <Spacer />
                                        <Button
                                            size="md"
                                            as={RouterLink}
                                            to={`/alarms/alarm-field-add/${field.id}`}
                                        >
                                            <Trans>Add Field Alarm</Trans>
                                        </Button>
                                    </Stack>
                                    {combinedAlarms.length > 0 ? (
                                        <SimpleGrid columns={{ base: 1, lg: 2, xl: 3, '2xl': 4 }} spacing={4}>
                                            {combinedAlarms.map((alarm) => (
                                                <Box key={alarm.id} p={3} borderRadius="lg" boxShadow="md" bg={boxColor} maxWidth="lg">
                                                    <Stack direction="row">
                                                        <RouterLink to={`/devices/${alarm.device}`}>
                                                            <Heading as="h2" size="lg">
                                                                {alarm.device_name ? (
                                                                    alarm.device_name
                                                                ) : (
                                                                    <Trans>{`${alarm.field_name} #${combinedAlarms.indexOf(alarm) + 1}`}</Trans>
                                                                )}
                                                            </Heading>
                                                        </RouterLink>
                                                        <Spacer />
                                                        {isFieldAlarm(alarm) ? (
                                                            <Button onClick={() => navigate(`alarm-field-edit/${alarm.id}`)} size="md" colorScheme="teal" variant="outline">
                                                                <Trans>Edit</Trans>
                                                            </Button>
                                                        ) : (
                                                            <Button onClick={() => navigate(`edit/${alarm.id}`)} size="md" colorScheme="teal" variant="outline">
                                                                <Trans>Edit</Trans>
                                                            </Button>
                                                        )}
                                                    </Stack>
                                                    <SimpleGrid columns={2} spacing={2}>
                                                        <Box>
                                                            <Text>{alarm.alarm_type}</Text>
                                                            <Text>
                                                                <Trans>Trigger:</Trans> {alarm.trigger_below_or_above ? "<" : ">"}{alarm.trigger_value}
                                                            </Text>
                                                            <Text>
                                                                {alarm.device_is_offline ? <Trans>Device is offline</Trans> : <Trans>Device is online</Trans>}
                                                            </Text>
                                                            <Text>
                                                                <Trans>Alarm:</Trans> {alarm.device_is_offline ? <Trans>Unknown</Trans> : alarm.is_active ? <Trans>Triggered</Trans> : <Trans>Not triggered</Trans>}
                                                            </Text>
                                                        </Box>
                                                        <Box>
                                                            <Flex alignItems="center" justifyContent="center" height="100%">
                                                                {alarm.is_active ? (
                                                                    <WarningTwoIcon color="red.500" w={8} h={8} />
                                                                ) : alarm.device_is_offline ? (
                                                                    <WarningTwoIcon color="orange.500" w={8} h={8} />
                                                                ) : (
                                                                    <CheckCircleIcon color="green.500" w={8} h={8} />
                                                                )}
                                                            </Flex>
                                                        </Box>
                                                    </SimpleGrid>
                                                </Box>
                                            ))}
                                        </SimpleGrid>
                                    ) : (
                                        <Text color="green.500">
                                            <Trans>You have no alarms</Trans>
                                        </Text>
                                    )}
                                </Box>
                            );
                        })}

                        <Box mb={6}>
                            <Stack direction="row" align="center" p={4}>
                                <Divider orientation='vertical' />
                                <Heading size="md" mb={2} fontSize={{ base: 'xl', lg: '2xl' }}>
                                    <Trans>Unattached alarms</Trans>
                                </Heading>
                                <Spacer />
                            </Stack>

                            {unattachedAlarms.length > 0 ? (
                                <SimpleGrid columns={{ base: 1, lg: 2, xl: 3, '2xl': 4 }} spacing={4}>
                                    {unattachedAlarms.map((alarm) => (
                                        <Box key={alarm.id} p={3} borderRadius="lg" boxShadow="md" bg={boxColor} maxWidth="lg">
                                            <Stack direction="row">
                                                <RouterLink to={`/devices/${alarm.device}`}>
                                                    <Heading as="h2" size="lg">
                                                        {alarm.device_name ? (
                                                            alarm.device_name
                                                        ) : (
                                                            <Trans>{`Unattached #${unattachedAlarms.indexOf(alarm) + 1}`}</Trans>
                                                        )}
                                                    </Heading>
                                                </RouterLink>
                                                <Spacer />
                                                {isFieldAlarm(alarm) ? (
                                                    <Button onClick={() => navigate(`alarm-field-edit/${alarm.id}`)} size="md" colorScheme="teal" variant="outline">
                                                        <Trans>Edit</Trans>
                                                    </Button>
                                                ) : (
                                                    <Button onClick={() => navigate(`edit/${alarm.id}`)} size="md" colorScheme="teal" variant="outline">
                                                        <Trans>Edit</Trans>
                                                    </Button>
                                                )}
                                            </Stack>
                                            <SimpleGrid columns={2} spacing={2}>
                                                <Box>
                                                    <Text>{alarm.alarm_type}</Text>
                                                    <Text>
                                                        <Trans>Trigger:</Trans> {alarm.trigger_below_or_above ? "<" : ">"}{alarm.trigger_value}
                                                    </Text>
                                                    <Text>
                                                        {alarm.device_is_offline ? <Trans>Device is offline</Trans> : <Trans>Device is online</Trans>}
                                                    </Text>
                                                    <Text>
                                                        <Trans>Alarm:</Trans> {alarm.device_is_offline ? <Trans>Unknown</Trans> : alarm.is_active ? <Trans>Triggered</Trans> : <Trans>Not triggered</Trans>}
                                                    </Text>
                                                </Box>
                                                <Box>
                                                    <Flex alignItems="center" justifyContent="center" height="100%">
                                                        {alarm.is_active ? (
                                                            <WarningTwoIcon color="red.500" w={8} h={8} />
                                                        ) : alarm.device_is_offline ? (
                                                            <WarningTwoIcon color="orange.500" w={8} h={8} />
                                                        ) : (
                                                            <CheckCircleIcon color="green.500" w={8} h={8} />
                                                        )}
                                                    </Flex>
                                                </Box>
                                            </SimpleGrid>
                                        </Box>
                                    ))}
                                </SimpleGrid>
                            ) : (
                                <Text color="green.500">
                                    <Trans>No unattached alarms</Trans>
                                </Text>
                            )}
                        </Box>
                    </VStack>
                </Center>
            </Box>
        </Navbar>
    );
};

export default AlarmsView;
