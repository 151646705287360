import { useState, useEffect } from 'react';
import { Box, Skeleton, Toast } from "@chakra-ui/react";
import { Line } from 'react-chartjs-2';
import useAxios from "../utils/useAxios";

interface Props {
  deviceId: number;
  isGraphloading: boolean;
  setIsGraphloading: (isLoading: boolean) => void;
}

const MiniMoistureGraph = ({ deviceId, isGraphloading, setIsGraphloading }: Props) => { 
    const api = useAxios();
    const [timestamps, setTimestamps] = useState<string[]>([]);
    const [values, setValues] = useState<Array<number|null>>([]);
  
    useEffect(() => {
      setIsGraphloading(true);
      const fetchData = async () => {
        if (!deviceId) return;
        try {
          const response = await api.get(`frontend/devices/plottable-data/${deviceId}/`, {
            params: { days: 5, aggregate: 'hour' }
          });
          if (response.data.soil_moisture && response.data.timestamp) {
            setValues(response.data.soil_moisture);
            // Only show date without year
            setTimestamps(response.data.timestamp.map((ts: string) =>
              new Date(ts).toLocaleString('nb-NO', { month: 'numeric', day: 'numeric' })
            ));
          }
        } catch (err) {
          console.error(err);
          Toast({
            title: "Error",
            description: "Failed to fetch data",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } finally {
          setIsGraphloading(false);
        }
      };
      fetchData();
    }, [deviceId]);
  
    if (isGraphloading) {
      return <Skeleton/>;
    }

    return (
      <Box>
        <Line
          data={{
            labels: timestamps,
            datasets: [
              {
                label: 'Soil Moisture',
                data: values,
                fill: true,
                backgroundColor: 'rgba(75,192,192,0.2)',
                borderColor: 'rgba(75,192,192,1)',
                pointRadius: 1
              }
            ]
          }}
          options={{
            maintainAspectRatio: false,
            scales: {
              yAxes: [
                {
                  ticks: {
                    suggestedMin: 0,
                    suggestedMax: 60
                  }
                }
              ]
            }
          }}
        />
      </Box>
    );
  };
  
  export default MiniMoistureGraph;