import Navbar from '../components/Navbar';
import useAxios from "../utils/useAxios";
import { useState, useEffect, useContext } from 'react';
import { useToast, Box, Center, Skeleton, Button, Flex, Text, Spacer, HStack, Divider } from "@chakra-ui/react";
import Map, {
  Marker,
  Popup,
  Source,
  Layer,
  GeolocateControl,
  FullscreenControl,
  NavigationControl,
  ScaleControl
} from 'react-map-gl';
import type { ViewState } from 'react-map-gl';
import { Link } from 'react-router-dom';
import { Heading, VStack } from "@chakra-ui/react";
import authContext from '../context/AuthContext';
import { DataDump } from '../components/DeviceTile';
import MiniMoistureGraph from './MiniMoistureGraph';

interface IData {
    id?: number;
    device_address?: string;
    version?: string;
    battery?: number;
    signal_strength?: number;
    mod?: number;
    interrupt?: number;
    soil_moisture?: number;
    soil_temperature?: number;
    soil_conductivity?: number;
    soil_dielectric_constant?: number;
    timestamp: string;
    created_at?: string;
    device?: number;
    air_temperature?: number;
    humidity?: number;
    lux?: number;
    battery_voltage?: number;
}

interface IDevice {
    device_id: number;
    device_name: string;
    device_description: string;
    device_type: number;
    data: IData;
    latitude: number;
    longitude: number;
    altitude?: number;
    accuracy?: number;
    altitudeAccuracy?: number;
    field?: number | null;
}

interface IFIeld{
  id: number;
  field_name: string;
  field_center_lat: number;
  field_center_lon: number;

}

function calculateMoistureColor(value: number|undefined, params: Record<string, number>): string {
    const lowMoistureColor: number[] = [255, 0, 0];
    const midMoistureColor: number[] = [0, 255, 0];
    const highMoistureColor: number[] = [0, 0, 255];

    if (value === undefined) {
        return `rgb(0,0,0)`;
    }

    const step = Math.min(Math.max((value - params.min) / (params.max - params.min), 0), 1);

    const blendColors = (color1: number[], color2: number[], ratio: number): number[] => {
        return color1.map((color, index) => Math.round(color + ratio * (color2[index] - color)));
    };

    let color: number[];

    if (step <= 0.5) {
        color = blendColors(lowMoistureColor, midMoistureColor, step * 2);
    } else {
        color = blendColors(midMoistureColor, highMoistureColor, (step - 0.5) * 2);
    }
    return `rgb(${color[0]},${color[1]},${color[2]})`;
}

function Pin({ size = 30, color = "rgb(0,0,0)" }: { size?: number, color?: string }) {
    const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;
    const pinStyle = {
        cursor: 'pointer',
        fill: '#d00',
        stroke: 'none'
    };
    return (
        <svg height={size} viewBox="0 0 24 24" style={{ ...pinStyle }}>
            <path d={ICON} fill={color} />
        </svg>
    );
}

const RenderDevices = ({ devices, plotkey, color_function, color_params } : 
    { devices: IDevice[], plotkey: string, color_function: (value: number|undefined, params: Record<string, number>) => string, color_params: Record<string, number> }
) => {
    const [popupInfo, setPopupInfo] = useState<IDevice | null>(null);
    const [isGraphLoading, setIsGraphLoading] = useState<boolean>(true);
    return (
        <>
            {devices.map((device, index) => (
                device.latitude && device.longitude && device.data && plotkey in device.data && device.data[plotkey as keyof IData] !== undefined && device.data[plotkey as keyof IData] !== null && (
                    <Marker
                        key={`${device.device_id}-${index}`}
                        latitude={device.latitude}
                        longitude={device.longitude}
                        anchor="bottom"
                        onClick={e => {
                            e.originalEvent.stopPropagation();
                            setPopupInfo(device);
                        }}
                    >
                        <Pin
                            size={30}
                            color={
                                new Date().getTime() - new Date(device.data?.timestamp).getTime() < 4 * 60 * 60 * 1000 ?
                                color_function(parseFloat(device.data[plotkey as keyof IData] as unknown as string), color_params)
                                : "#a3a3a3"
                            }
                        />
                    </Marker>
                )
            ))}

            {popupInfo && (
                <Popup
                    anchor="top"
                    longitude={Number(popupInfo?.longitude)}
                    latitude={Number(popupInfo?.latitude)}
                    onClose={() => setPopupInfo(null)}
                    maxWidth="100%"
                >
                    <Flex p={2}>
                        <HStack>
                            <Box width={{ base: "200px" }}>
                                <VStack spacing={1} fontSize={13} textColor="black" mr={2}>
                                    <Heading size="md">
                                        {popupInfo.device_name ? popupInfo.device_name : "Unnamed device"}
                                    </Heading>
                                    <Link to={`/devices/${popupInfo.device_id}`}>
                                        <DataDump device={popupInfo} />
                                    </Link>
                                </VStack>
                            </Box>

                            <Box
                                position="relative"
                                width={{ base: "300px" }}
                                height={{ base: "150px" }}
                            >
                                <MiniMoistureGraph
                                    key={popupInfo.device_id}
                                    deviceId={popupInfo.device_id}
                                    isGraphloading={isGraphLoading}
                                    setIsGraphloading={setIsGraphLoading}
                                />
                                    {isGraphLoading && (
                                        <Skeleton
                                            position="absolute"
                                            top={0}
                                            left={0}
                                            width="100%"
                                            height="100%"
                                        />
                                    )}
                            </Box>
                        </HStack>
                    </Flex>
                </Popup>
            )}
        </>
    );
};

const RenderPolygon = ({ field }: { field: any }) => {
    if (!field.area) return null;
    const geojson = {
        type: 'FeatureCollection',
        features: Object.values(field.area).map((area: any) => ({
            ...area,
            type: 'Feature',
            geometry: {
                ...area.geometry,
                type: 'Polygon'
            }
        }))
    };
    return (
        <Source
            id={`field-${field.id}`}
            type="geojson"
            data={geojson as any}
        >
            <Layer
                id={`field-line-${field.id}`}
                type="line"
                paint={{ 'line-color': '#088', 'line-width': 2 }}
            />
            <Layer
                id={`field-fill-${field.id}`}
                type="fill"
                paint={{ 'fill-color': '#088', 'fill-opacity': 0.2 }}
            />
        </Source>
    );
};

const DeviceMapView = () => {
    const api = useAxios();
    const toast = useToast();
    const { userSettings } = useContext(authContext);

    const [isLoading, setIsLoading] = useState(true);
    const [devices, setDevices] = useState<IDevice[]>([]);
    const [fields, setFields] = useState<IFIeld[]>([]);

    const [viewFlag, setViewFlag] = useState<boolean>(false);

    const [viewState, setViewState] = useState<ViewState>({
        longitude: 10,
        latitude: 50,
        zoom: 4,
        bearing: 0,
        pitch: 0,
        padding: { top: 0, bottom: 0, left: 0, right: 0 },
      });
      
      useEffect(() => {
        const fetchData = async () => {
          try {
            const [devicesRes, fieldsRes] = await Promise.all([
              api.get("frontend/devices/current-data/"),
              api.get("agtech/fields/")
            ]);
            setDevices(devicesRes.data);
            setFields(fieldsRes.data);

            // Default field view
            if (fieldsRes.data.length > 0 && viewFlag === false) {
                setViewFlag(true);
                setViewState((prev) => ({
                    ...prev,
                    longitude: fieldsRes.data[0].field_center_lon,
                    latitude: fieldsRes.data[0].field_center_lat,
                    zoom: 15
                }));
            }
          } catch (error: any) {
            if (error.response?.status !== 401) {
              toast({
                title: "Error fetching data",
                status: "error",
                duration: 9000,
                isClosable: true
              });
            }
          } finally {
            setIsLoading(false);
          }
        };
        fetchData();
      }, []);


      const goToField = (field: IFIeld) => {
        if (field.field_center_lon && field.field_center_lat) {
          setViewState((prev) => ({
            ...prev,
            longitude: field.field_center_lon,
            latitude: field.field_center_lat,
            zoom: 15,
            transitionDuration: 1000
          }));
        }
      };

      return (
        <Navbar>
          <Box width="100%" maxW="1200px" mx="auto">
            <Box width="100%" height={{ base: 500, md: 500, lg: 700 }}>
              {isLoading ? (
                <Center height="100%">
                  <Skeleton height="100%" width="100%" />
                </Center>
              ) : (
                <Map
                  mapLib={import('mapbox-gl')}
                  viewState={{ ...viewState, width: window.innerWidth, height: window.innerHeight }}
                  onMove={(evt) => setViewState(evt.viewState)}
                  mapStyle="mapbox://styles/mapbox/satellite-v9"
                  mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                  style={{ width: "100%", height: "100%" }}
                >
                  <Source
                    id="mapbox-dem"
                    type="raster-dem"
                    url="mapbox://mapbox.mapbox-terrain-dem-v1"
                    tileSize={512}
                    maxzoom={14}
                  />
                  
                  {fields.map(field => (
                    <RenderPolygon key={field.id} field={field} />
                  ))}
    
                  <RenderDevices
                    devices={devices}
                    plotkey="soil_moisture"
                    color_function={calculateMoistureColor}
                    color_params={{
                      min: userSettings.moisture_plot_minimum,
                      max: userSettings.moisture_plot_maximum
                    }}
                  />
    
                  <GeolocateControl />
                  <FullscreenControl />
                  <NavigationControl />
                  <ScaleControl />
                </Map>
              )}
            </Box>
    
            <Box p={4}>
                <Text>Field List</Text>
              {fields.map(field => (
                <Box
                  key={field.id}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={2}
                  p={3}
                  minH="60px"
                >
                  <Text fontSize="sm" color="gray.600" textDecor="bold">{field.field_name}</Text>
                  <Button colorScheme="teal" size="sm" onClick={() => goToField(field)}>
                    Go to field
                  </Button>
                </Box>
                
              ))}
            </Box>
          </Box>
        </Navbar>
      );
};

export default DeviceMapView;
