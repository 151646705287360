import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Box,
    Heading,
    useToast,
    Button,
    FormControl,
    FormLabel,
    Input,
    useColorModeValue,
    Center,
    VStack,
    Text,
    Stack,
    SimpleGrid,
    Spacer,
    Flex,
    Container,
    HStack,
    Checkbox,
} from "@chakra-ui/react";

import {
    CheckCircleIcon, WarningTwoIcon,
} from '@chakra-ui/icons';

import Navbar from '../../components/Navbar';
import useAxios from "../../utils/useAxios";
import { Trans } from "@lingui/react/macro";

interface Data {
    air_temperature: number;
    humidity: number;
    lux: number;
    battery_voltage: number;
    timestamp: string;
    soil_moisture?: number;
    soil_conductivity?: number;
}

interface Device {
    device_id: number;
    device_name: string;
    device_description: string;
    device_type: number;
    latitude: number | null;
    longitude: number | null;
    accuracy: number | null;
    sensor_depth: number | null;
    data?: Data;
}

interface IrrigationValveState {
    state: boolean;
    confirmed_at: string;
}

interface FlowMeasurement {
    current_litres_per_minute: number;
    total_water_volume_litres: number;
    last_updated_at: string;
}


interface IrrigationOverview {
    id: number;
    user_device_controller: number;
    user_device_sensor: number | null;
    mode: number;
    start_time: string | null;
    end_time: string | null;
    time_zone: string;
    period: number | null;
    duty_cycle: number | null;
    setpoint: number | null;
    controller_data: Device;
    sensor_data?: Device;
    current_valve_state?: IrrigationValveState;
    flow_measurement?: FlowMeasurement;
    water_meter_start_date: string;
}

const formatTimestamp = (timestamp: string): string => {
    return new Date(timestamp).toLocaleString('default', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    });
};

const formatTimeOnly = (time: string): string => {
    console.log('Raw time:', time);
    const currentDate = new Date();
    const [hours, minutes, seconds] = time.split(':').map(Number);
    currentDate.setHours(hours, minutes, seconds || 0);
    if (isNaN(currentDate.getTime())) {
        console.error('Invalid Time:', time);
        return 'Invalid Time';
    }
    return currentDate.toLocaleTimeString('default', {
        hour: '2-digit',
    });
};

function formatPeriod(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    if (hours > 0) {
        var ret_string = `${hours} hr`;	
        if (minutes > 0) {
            ret_string += ` ${minutes} min`;
        }
        return ret_string;
    } else {
        var ret_string = `${minutes} min`;
        if (remainingSeconds > 0) {
            ret_string += ` ${remainingSeconds.toFixed(0)} sec`;
        }
        return ret_string;
    }
}

const controllerModeLookup: { [key: number]: JSX.Element } = {
    0: <Trans>Manual</Trans>,
    1: <Trans>Schedule</Trans>,
    2: <Trans>Auto</Trans>
};


const IrrigationControllerTile = ({ irrigation }: { irrigation: IrrigationOverview }) => {

    const labelTextColor = useColorModeValue('gray.700', 'gray.300');

    const boxColor = useColorModeValue('white', 'gray.900');

    const api = useAxios();

    const toast = useToast();

    const handleValveOn = () => {
        api.post(`/agtech/irrigation/valve/${irrigation.id}/`, { state: true })
            .then((response) => {
                console.log('Valve On', response.data);
                toast({
                    title: <Trans>Valve On</Trans>,
                    description: response.data.message,
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            })
            .catch((error) => {
                console.error('Error turning valve on', error);
                toast({
                    title: <Trans>Error turning valve on</Trans>,
                    description: error.message,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            });
    }

    const handleValveOff = () => {
        api.post(`/agtech/irrigation/valve/${irrigation.id}/`,
            { 

                state: false
            })
            .then((response) => {
                console.log('Valve Off', response.data);
                toast({
                    title: <Trans>Valve Off</Trans>,
                    description: response.data.message,
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            })
            .catch((error) => {
                console.error('Error turning valve off', error);
                toast({
                    title: <Trans>Error turning valve off</Trans>,
                    description: error.message,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            });
    }


    return (
    <Box key={irrigation.id} p={4} shadow="md" borderRadius="lg" bg={boxColor} maxWidth={600}>
    <VStack>
        <HStack>
        <Link to={`/irrigation/${irrigation.id}`} key={irrigation.id}>
            <Heading as="h2" size="lg">{irrigation.controller_data.device_name}</Heading>
        </Link>
            <Spacer />
            {irrigation.controller_data?.data?.timestamp && (new Date().getTime() - new Date(irrigation.controller_data.data?.timestamp).getTime() < 4 * 60 * 60 * 1000) ? (
                    <>
                        <CheckCircleIcon color="green.500" />
                        <Text fontWeight={100}><Trans>Online</Trans></Text>
                    </>
                ) : (
                    <>
                        <WarningTwoIcon color="red.500" />
                        <Text><Trans>Offline</Trans></Text>
                    </>
                )}
        </HStack>
        <Flex direction="row">
            <Box p={5}>
                    <Text color={labelTextColor}><Trans>Mode:</Trans></Text>
                    <Heading size="lg">{controllerModeLookup[irrigation.mode]}</Heading>
                    {(irrigation.mode === 1 || irrigation.mode === 2) && (
                        <>
                            <Text color={labelTextColor}><Trans>Irrigation Time:</Trans></Text>
                            { !irrigation.start_time || !irrigation.end_time ? (
                                <Text><Trans>Always</Trans></Text>
                            ) : (
                                <Text fontWeight={600}>
									{irrigation.start_time ? formatTimeOnly(irrigation.start_time) : 'N/A'} 
									<Trans> to </Trans> 
									{irrigation.end_time ? formatTimeOnly(irrigation.end_time) : 'N/A'}
								</Text>
                            )}
                                <>
                                    <Text color={labelTextColor}><Trans>Interval:</Trans></Text>
                                    <Text fontWeight={600}>{ irrigation.period && formatPeriod(irrigation.period)}</Text>
                                    <Text color={labelTextColor}><Trans>Duration:</Trans></Text>
                                    <Text fontWeight={600}>{ irrigation.period && irrigation.duty_cycle && formatPeriod(irrigation.duty_cycle/2**16*irrigation.period)}</Text>
                                </>
                            </>
                    )}
                    {irrigation.mode === 2 && (
                        <>
                            <Text><Trans>Setpoint:</Trans> {irrigation.setpoint} %</Text>
                        </>
                    )}
                    {irrigation.mode === 0 && (
                        <>
                        <Text color={labelTextColor}><Trans>State:</Trans></Text>
                        {irrigation.current_valve_state ? (
                            <>
                                <Text fontWeight={600}>
									{irrigation.current_valve_state.state ? <Trans>Open</Trans> : <Trans>Closed</Trans>}
								</Text>
                                <Text color={labelTextColor}><Trans>Time set:</Trans></Text>
                                <Text fontWeight={600}>
                                    {irrigation.current_valve_state?.confirmed_at ? formatTimestamp(irrigation.current_valve_state.confirmed_at) : <Trans>pending</Trans>}
                                </Text>
                            </>
                        ) : (
                            <Text fontWeight={600}><Trans>Unknown</Trans></Text>
                        )}                   


                        <Box mt={2}>
                            <Button onClick={handleValveOn} size="sm" mr="1"><Trans>Open</Trans></Button>
                            <Button onClick={handleValveOff} size="sm"><Trans>Close</Trans></Button>
                        </Box>
                        </>
                    )}
                </Box>
                <VStack px={6}>
                    <Box pt={5}>
                        { irrigation.sensor_data?.data?.soil_moisture && (
                            <Link to={`/devices/${irrigation.sensor_data.device_id}`} key={irrigation.sensor_data.device_id}>
                                <Text color={labelTextColor}><Trans>Moisture:</Trans></Text>
                                <Heading size="lg">{irrigation.sensor_data?.data?.soil_moisture?.toFixed(1)} %</Heading>
                                <Text color={labelTextColor} fontWeight={100}>{irrigation.sensor_data?.data?.timestamp && formatTimestamp(irrigation.sensor_data.data.timestamp)}</Text>
                            </Link>
                        )}
                    </Box>
                    <Box pt={2}>
                        { irrigation.flow_measurement && (
                            <>
                                <Text color={labelTextColor}><Trans>Current flow:</Trans></Text>
                                <Heading size="md">{irrigation.flow_measurement.current_litres_per_minute.toFixed(1)} l/min</Heading>
                                <Text color={labelTextColor} fontWeight={100}>{irrigation.flow_measurement.last_updated_at && formatTimestamp(irrigation.flow_measurement.last_updated_at)}</Text>
                                <Text color={labelTextColor}><Trans>Water meter:</Trans></Text>
                                <Heading size="md">{irrigation.flow_measurement.total_water_volume_litres.toFixed(1)} l</Heading>
                            </>
                        )}
                    </Box>
                </VStack>
        </Flex>
    </VStack>
    </Box>
    );
}


const IrrigationOverview = () => {
    const [irrigationOverview, setIrrigationOverview] = useState<IrrigationOverview[] | null>(null);
    const [isPolling, setIsPolling] = useState(false);

    const api = useAxios();

    const toast = useToast();

    const fetchIrrigationOverview = async () => {
        api.get('/agtech/irrigation/')
            .then((response) => {
                console.log('Irrigation Overview fetched', response.data)
                setIrrigationOverview(response.data);
            })
            .catch((error) => {
                console.error('Error fetching irrigation overview', error);
                toast({
                    title: <Trans>Error fetching irrigation overview</Trans>,
                    description: error.message,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            });
    };

    useEffect(() => {
        fetchIrrigationOverview();
    }, []);

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;
        if (isPolling) {
            interval = setInterval(fetchIrrigationOverview, 5000);
        } else if (!isPolling && interval) {
            clearInterval(interval);
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [isPolling]);

    return (
        <Navbar>
            <Container maxW="container.2xl" mt={10}>
                <Heading as="h1" size="xl" textAlign={"center"} mb={5}>
					<Trans>Irrigation Overview</Trans>
				</Heading>
                <Checkbox isChecked={isPolling} onChange={(e) => setIsPolling(e.target.checked)} textColor={'gray.500'}>
                    <Trans>Enable live updates</Trans>
                </Checkbox>
                <SimpleGrid minChildWidth={380} spacing={4} mt="4">
                    {irrigationOverview && irrigationOverview.map((irrigation) => (
                        <IrrigationControllerTile key={irrigation.id} irrigation={irrigation} />
                    ))}
                </SimpleGrid>
            </Container>
        </Navbar>
    );
};

export { IrrigationControllerTile, IrrigationOverview };
export default IrrigationOverview;
