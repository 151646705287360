import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    HStack,
    InputRightElement,
    Stack,
    Button,
    Heading,
    Text,
    useColorModeValue,
    Link,
    useToast,
  } from '@chakra-ui/react';
  import { useState } from 'react';
  import { useParams, useNavigate } from 'react-router-dom';
  import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
  import { Trans } from "@lingui/react/macro";

// endpoint: /users/dj-rest-auth/password/reset/confirm/ with password1, password2, uid, and token


export default function PasswordResetConfirm() {
    const toast = useToast();
    const navigate = useNavigate();
    const { uid, token } = useParams<{ uid: string, token: string }>();

    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');

    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const response = await fetch(process.env.REACT_APP_API_URL + 'api/v1/users/dj-rest-auth/password/reset/confirm/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                new_password1: password1,
                new_password2: password2,
                uid: uid,
                token: token,
            }),
        });
        
        if (response.ok) {
            toast({
                title: <Trans>Password reset successful</Trans>,
                status: "success",
                duration: 9000,
                isClosable: true,
            });
            navigate('/login');
        }
        else {
            // get response data
            const data = await response.json();
            console.log(data);
            toast({
                title: <Trans>Error resetting password</Trans>,
                description: JSON.stringify(data),
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    }


    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}>
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                <Stack align={'center'}>
                    <Heading fontSize={'4xl'}><Trans>Reset your password</Trans></Heading>
                </Stack>
                <Box
                    rounded={'lg'}
                    bg={useColorModeValue('white', 'gray.700')}
                    boxShadow={'lg'}
                    p={8}>
                    <Stack spacing={4}>
                        <form onSubmit={handleSubmit}>
                            <FormControl id="password1" isRequired>
                                <FormLabel><Trans>Password</Trans></FormLabel>
                                <InputGroup>
                                    <Input
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder="Enter your password"
                                        value={password1}
                                        onChange={(e) => setPassword1(e.target.value)}
                                    />
                                    <InputRightElement width="4.5rem">
                                        <Button h="1.75rem" size="sm" onClick={() => setShowPassword(!showPassword)}>
                                            {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>
                            </FormControl>
                            <FormControl mt={2} id="password2" isRequired>
                                <FormLabel><Trans>Confirm Password</Trans></FormLabel>
                                <Input
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Confirm your password"
                                    value={password2}
                                    onChange={(e) => setPassword2(e.target.value)}
                                />
                            </FormControl>
                            <Stack spacing={10}>
                                <Button
                                mt={4}
                                    type="submit"
                                    colorScheme='green'
                                    _hover={{
                                        bg: 'blue.500',
                                    }}>
                                    <Trans>Reset password</Trans>
                                </Button>
                            </Stack>
                        </form>
                    </Stack>
                </Box>
            </Stack>
        </Flex>
    );
}