// I have this endpoint with the following structure:
// agtech/fields/15/notes/ with get, post, put, delete methods available. The fields are:
// {
//     "id": 2,
//     "field": 14,
//     "note_title": "3",
//     "note_text": "cdcdcdc",
//     "created_at": "2024-04-18 11:35:54.927407+0200",
//     "modified_at": "2024-04-18 11:40:20.303844+0200"
// },


import { useEffect, useState, useContext, useRef } from 'react';

import { AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, Button, useDisclosure } from "@chakra-ui/react";

import {
    Box,
    Heading,
    useToast,
    FormControl,
    FormLabel,
    Input,
    useColorModeValue,
    Center,
    VStack,
    Text,
    Stack,
    SimpleGrid,
    Container,
    Skeleton,
    Toast,
    Switch,
    Textarea
} from "@chakra-ui/react"

import { Field } from '../../types/fieldInterface';

import useAxios from "../../utils/useAxios";
import { Trans } from "@lingui/react/macro";

interface Note {
    id: number;
    field: number;
    note_title: string;
    note_text: string;
    created_at: string;
    modified_at: string;
}


interface NotesWidgetProps {
    field: Field;
}

const NotesWidget: React.FC<NotesWidgetProps> = ({ field }) => {
    const [notes, setNotes] = useState<Note[]>([]);
    const [noteTitle, setNoteTitle] = useState<string>('');
    const [noteText, setNoteText] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [editNote, setEditNote] = useState<Note | null>(null);
    const toast = useToast();
    const api = useAxios();

    const initialDisplayCount = 4;
    const [displayCount, setDisplayCount] = useState(initialDisplayCount);
    const [showAll, setShowAll] = useState(false);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef<HTMLButtonElement | null>(null);
    const [noteToDelete, setNoteToDelete] = useState<Note | null>(null);


    useEffect(() => {
        if (!field) {
            return;
        }
        api.get(`/agtech/fields/${field.id}/notes/`)
            .then(response => {
                setNotes(response.data);
                setLoading(false);
            })
            .catch(error => {
                toast({
                    title: "Error loading notes",
                    description: error.message,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            });
    }, [field]);

    const handleAddNote = () => {
        api.post(`/agtech/fields/${field.id}/notes/`, {
            field: field.id,
            note_title: noteTitle,
            note_text: noteText,
        })
            .then(response => {
                setNotes([response.data, ...notes]);
                setNoteTitle('');
                setNoteText('');
                toast({
                    title: <Trans>Note added</Trans>,
                    description: <Trans>Note has been added successfully</Trans>,
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            })
            .catch(error => {
                toast({
                    title: "Error adding note",
                    description: error.message,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            });
    }

    const handleEditNote = () => {
        if (editNote) {
            api.put(`/agtech/fields/${field.id}/notes/${editNote.id}/`, {
                field: field.id,
                note_title: noteTitle,
                note_text: noteText,
            })
                .then(response => {
                    const updatedNotes = notes.map(note => {
                        if (note.id === editNote.id) {
                            return response.data;
                        } else {
                            return note;
                        }
                    });
                    setNotes(updatedNotes);
                    setNoteTitle('');
                    setNoteText('');
                    setEditNote(null);
                    toast({
                        title: <Trans>Note updated</Trans>,
                        description: <Trans>Note has been updated successfully</Trans>,
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    });
                }
                )
                .catch(error => {
                    toast({
                        title: <Trans>Error updating note</Trans>,
                        description: error.message,
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                });
        }
    }


    const handleDeleteNote = (note: Note) => {
        setNoteToDelete(note);
        onOpen();
    };
    
    const confirmDelete = () => {
        if (!noteToDelete) {
            onClose();
            return;
        }
        api.delete(`/agtech/fields/${field.id}/notes/${noteToDelete.id}/`)
            .then(() => {
                setNotes(notes.filter(n => n.id !== noteToDelete.id));
                toast({
                    title: <Trans>Note deleted</Trans>,
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            })
            .catch(error => {
                toast({
                    title: <Trans>Error deleting note</Trans>,
                    description: error.message,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            });
        onClose();
    };

    const handleEditClick = (note: Note) => {
        setEditNote(note);
        setNoteTitle(note.note_title);
        setNoteText(note.note_text);
    }

    return (
        <Box>
            
            <Heading size="lg" mb={4}><Trans>Notes</Trans></Heading>
            <VStack spacing={4}>
                <FormControl id="noteTitle" isRequired>
                    <FormLabel><Trans>Note Title</Trans></FormLabel>
                    <Input type="text" value={noteTitle} onChange={(e) => setNoteTitle(e.target.value)} />
                </FormControl>
                <FormControl id="noteText">
                    <FormLabel><Trans>Note Text</Trans></FormLabel>
                    <Textarea value={noteText} onChange={(e) => setNoteText(e.target.value)} />
                </FormControl>
                <Button onClick={editNote ? handleEditNote : handleAddNote} isDisabled={!noteTitle}>
                    {editNote ? <Trans>Edit Note</Trans> : <Trans>Add Note</Trans>}
                </Button>
            </VStack>
            <SimpleGrid columns={1} spacing={4} mt={4}>
    {loading ? (
        <Skeleton height="100px" />
    ) : notes.slice(0, showAll ? notes.length : displayCount).map(note => (
        <Box key={note.id} p={4} borderWidth="1px" borderRadius="md">
            <Stack direction="row" justify="space-between">
                <Heading size="md">{new Date(note.created_at).toLocaleDateString()}: {note.note_title}</Heading>
                <Stack direction="row">
                    <Button onClick={() => handleEditClick(note)}><Trans>Edit</Trans></Button>
                    <Button onClick={() => handleDeleteNote(note)}><Trans>Delete</Trans></Button>
                </Stack>
            </Stack>
            <Text whiteSpace="pre-line">{note.note_text}</Text>
        </Box>
    ))}
    {!showAll && displayCount < notes.length && (
        <Button onClick={() => setShowAll(true)}><Trans>Show All</Trans></Button>
    )}
    {showAll && (
        <Button onClick={() => { setDisplayCount(initialDisplayCount); setShowAll(false); }}>
            <Trans>Show Less</Trans>
        </Button>
    )}
</SimpleGrid>

<AlertDialog
    isOpen={isOpen}
    leastDestructiveRef={cancelRef}
    onClose={onClose}
>
    <AlertDialogOverlay>
        <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                <Trans>Delete Note</Trans>
            </AlertDialogHeader>

            <AlertDialogBody>
                <Trans>Are you sure? You can't undo this action afterwards.</Trans>
            </AlertDialogBody>

            <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                    <Trans>Cancel</Trans>
                </Button>
                <Button colorScheme='red' onClick={confirmDelete} ml={3}>
                    <Trans>Delete</Trans>
                </Button>
            </AlertDialogFooter>
        </AlertDialogContent>
    </AlertDialogOverlay>
</AlertDialog>
        </Box>
    );
}

export default NotesWidget;